import { times } from "lodash";
import {
  FROM_12AM_TO_5AM,
  FROM_5AM_TO_3PM,
  FROM_3PM_TO_6PM,
  FROM_6PM_TO_12AM,
  FROM_5AM_TO_9AM,
  FROM_9AM_TO_5PM,
  FROM_5PM_TO_12AM,
  ALL_HOURS,
} from "../constants/";
import allHoursImage from "./chargingPatterns/allhours.png";
import from12amto6am from "./chargingPatterns/12am-6am.png";
import from6amto4pm from "./chargingPatterns/6am-4pm.png";
import from4pmto9pm from "./chargingPatterns/4pm-9pm.png";
import from9pmto12am from "./chargingPatterns/9pm-12am.png";

const DST = {
  2021: {
    startHour: 1610,
    endHour: 7322,
  },
};

const dstAdjustedHour = (hour, year) => {
  const { startHour, endHour } = DST[year];
  const isWithinDST = hour >= startHour && hour <= endHour;
  return isWithinDST ? (hour + 1) % 24 : hour % 24;
};

// NOTE: this is not a complete list of leap years, but is sufficient for the data
// provided
const isLeapYear = (year) => [2016, 2020].includes(year);
const hoursInYear = (year) => (isLeapYear(year) ? 8784 : 8760);

const chargingPatterns = [
  {
    id: FROM_12AM_TO_5AM,
    title: "12-5 a.m.",
    value: "$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 0 && hour < 5 ? 1.1 : 0;
        }),
    },
    image: from12amto6am,
    description: "Recommended!",
  },
  {
    id: FROM_5AM_TO_3PM,
    title: "5 a.m. to 3 p.m.",
    value: "$$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 5 && hour < 15 ? 1.1 : 0;
        }),
    },
    image: from6amto4pm,
  },
  {
    id: FROM_3PM_TO_6PM,
    title: "3-6 p.m.",
    value: "$$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 15 && hour < 18 ? 1.1 : 0;
        }),
    },
    image: from4pmto9pm,
  },
  {
    id: FROM_6PM_TO_12AM,
    title: "6 p.m. to 12 a.m.",
    value: "$$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 18 && hour < 24 ? 1.1 : 0;
        }),
    },
    image: from9pmto12am,
  },
  {
    id: FROM_5AM_TO_9AM,
    title: "5-9 a.m.",
    value: "$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 5 && hour < 9 ? 1.1 : 0;
        }),
    },
    image: from9pmto12am,
  },
  {
    id: FROM_9AM_TO_5PM,
    title: "9 a.m. to 5 p.m.",
    value: "$$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 9 && hour < 17 ? 1.1 : 0;
        }),
    },
    image: from9pmto12am,
  },
  {
    id: FROM_5PM_TO_12AM,
    title: "5 p.m. to 12 a.m.",
    value: "$$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const hour = dstAdjustedHour(i, year);
          return hour >= 17 && hour < 24 ? 1.1 : 0;
        }),
    },
    image: from9pmto12am,
  },
  {
    id: ALL_HOURS,
    title: "All Hours",
    value: "$?",
    loadProfile: {
      data: () => times(hoursInYear(2021), (_) => 1.1),
    },
    image: allHoursImage,
    description: "Unpredictable!",
  },
];


export function combinedLoadProfile(maySepLoadProfile, octAprLoadProfile) {
  return {
    data: (year) => {
      const hours = hoursInYear(year);
      
      return times(hours, (i) => {
        const date = new Date(year, 0, 1); // Create a new data on the begining of the year
        date.setHours(i); // adjust hours to `i` on year
        const month = date.getMonth(); // Get month

        // may (4) to sep (8) use the first, appart from that, use the other
        if (month >= 4 && month <= 8) {
          return maySepLoadProfile.data(year)[i];
        } else {
          return octAprLoadProfile.data(year)[i];
        }
      });
    }
  };
}


export default chargingPatterns;
