


const residentialRateNC = () => {
  return {
    name: 'Residential Rate',
    title: 'Residential Electric Rate Structure',
    rateElements: [
      {
        rateElementType: 'FixedPerMonth',
        name: 'Basic Service Charge',
        classification: 'fixed',
        billingCategory: 'supply',
        rateComponents: [
          {
            charge: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10], // $10/month
            name: 'Basic Service Charge',
          },
        ],
      },
      {
        rateElementType: 'EnergyTimeOfUse',
        name: 'Energy Charges',
        classification: 'energy',
        billingCategory: 'supply',
        rateComponents: [
          {
            charge: 0.15, // $0.15/kWh
            months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // All year
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // All week
            hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], // All day
            name: 'All hours energy charge',
          },
          {
            charge: 0.20, // $0.20/kWh
            months: [6, 7, 8, 9], // Summer months
            daysOfWeek: [1, 2, 3, 4, 5], // Weekdays
            hourStarts: [14, 15, 16, 17, 18, 19], // Peak hours
            name: 'Summer peak hours charge',
          },
        ],
      },
      {
        rateElementType: 'SurchargeAsPercent',
        name: 'Sales Tax',
        rateComponents: [
          {
            charge: 0.07, // 7%
            name: 'Sales Tax',
          },
        ],
      },
    ],
  }
};

export default residentialRateNC;
