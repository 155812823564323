import React, { useContext } from 'react';
import Select from '../../../../components/shared/InputElements/Select';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import { FormattedMessage } from 'react-intl';

const SelectCurrentRate = ({ rates }) => {
  const userPrefs = useContext(UserPrefsContext);
  const currentRateTranslations = {
   "offPeak": "Off-Peak Plan",
   "standardVI": "Standard Residential Service",
   "standardNC": "Standard Residential Service"
  }

  return (
    <Select
      id="home-charging-advisor-current-rate-id"
      value={userPrefs.get("currentRateId")}
      handler={(e) => userPrefs.set({ currentRateId: e.target.value })}
      optionNames={rates.map(rate => currentRateTranslations?.[rate.id.split(" ").join("")] || rate.title[0])}
      optionValues={rates.map(rate => rate.id)}
      label={<FormattedMessage
        id="hcaCurrentElectricRate"
        defaultMessage="Current Rate"
        description="Current Rate"
      />}
    />
  );
};

export default SelectCurrentRate;
