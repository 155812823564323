import VehicleCost from './CostOfOwnership/calcs/VehicleCost';
import capitalizedInPurchase from '../incentives/capitalization/capitalizedInPurchase';
import capitalizedInLease from '../incentives/capitalization/capitalizedInLease';
import nonCapitalizedInLease from '../incentives/capitalization/nonCapitalizedInLease';

const getPaymentDetails = (
  vehicle,
  monthsOfOwnership,
  interestRateAsBasisPoints,
  milesDrivenAnnually,
  salesTax,
  downPayment,
  incentivesFilters = []
) => {
  const incentivesForPurchase = incentivesFilters.length 
    ? capitalizedInPurchase(vehicle, incentivesFilters) 
    : capitalizedInPurchase(vehicle);
 
  const incentivesForLease =
    capitalizedInLease(vehicle) + nonCapitalizedInLease(vehicle);

  const afterIncentives = VehicleCost.afterIncentives(vehicle, incentivesFilters);
  const loanDownPayment = VehicleCost.downPayment(
    vehicle,
    salesTax,
    downPayment
  );
  const loanAmountPerMonth = VehicleCost.monthlyLoanPayment(
    vehicle,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax,
    downPayment
  );
  const leaseFirstPayment = VehicleCost.firstLeasePayment(
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax,
    downPayment
  );

  const leaseAmountPerMonth = VehicleCost.monthlyLeasePayment(
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax,
    downPayment
  );

  const yearsOfOwnership = monthsOfOwnership / 12;

  return {
    afterIncentives: afterIncentives,
    loanAmountPerMonth: loanAmountPerMonth,
    downPayment: loanDownPayment,
    leaseAmountPerMonth: leaseAmountPerMonth,
    leaseFirstPayment: leaseFirstPayment,
    msrp: vehicle.msrp,
    interestRateAsBasisPoints: interestRateAsBasisPoints,
    yearsOfOwnership: yearsOfOwnership,
    totalIncentivesForPurchase: incentivesForPurchase,
    totalIncentivesForLease: incentivesForLease,
  };
};

export default getPaymentDetails;
