import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane } from 'reactstrap';

import {
  ALL_STATIONS_TAB_ID,
  ROUTE_MAP_TAB_ID,
} from '../../../constants/mapTabs';
import ChargingMap from '../LocationMap/ChargingMap/ChargingMap';

import './TabbedMaps.scss';
import TravelRouteMap from '../../../components/LocationMap/TravelRouteMap/TravelRouteMap';
import useChargingStations from '../../../hooks/useChargingStations';
import ChargingStationsList from '../../../components/LocationMap/ChargingStationsList/ChargingStationsList';
import filterWithinBounds from '../../../utils/Helpers/filterWithinBounds';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';


const TabbedMaps = ({
  chargingStationsFilterFn,
  electricVehicles,
  activeTabId,
  toggle,
  userLocation,
  zipcode,
  pspsEvents = [],
  isPage
}) => {
  const [bounds, setBounds] = useState({
    nw: undefined,
    ne: undefined,
    sw: undefined,
    se: undefined,
  });
  const userPrefs = useContext(UserPrefsContext);
  const { chargingStations, fetchChargingStations } = useChargingStations();
  const visibleChargingStations = filterWithinBounds(chargingStations, bounds);
  const filteredChargingStations = visibleChargingStations;
  const powerFilteredChargingStations =
    userPrefs.get('vehicleTypeFilter') === 'All'
      ? filteredChargingStations
      : filteredChargingStations.filter((station) =>
          userPrefs.get('vehicleTypeFilter') === 'highPower'
            ? Number(station.ev_dc_fast_num)
            : userPrefs.get('vehicleTypeFilter') === 'public'
            ? station.access_code === 'public'
            : station.access_code !== 'public'
        );

  return (
    <div className="TabbedMaps">
      <div className="container tabbed-container">
        <div className="tabbed-maps-panel">
          
          <TabContent activeTab={activeTabId}>
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED && (
              <TabPane tabId={ALL_STATIONS_TAB_ID}>
                <ChargingMap
                  userLocation={userLocation}
                  isVisible={activeTabId === ALL_STATIONS_TAB_ID}
                  zipcode={zipcode}
                  toggleWithHistory={toggle}
                  isPage={isPage}
                  additionalFetch={fetchChargingStations}
                  additionalBounds={setBounds}
                />
              </TabPane>
            )}
            {process.env.REACT_APP_PAGES_MAP_ROUTE_ENABLED && (
              <TabPane tabId={ROUTE_MAP_TAB_ID}>
                <TravelRouteMap
                  onlyTotalRange
                  chargingStationsFilterFn={chargingStationsFilterFn}
                  electricVehicles={electricVehicles}
                  isVisible={activeTabId === ROUTE_MAP_TAB_ID}
                  canIgnoreLowPowerStations={false}
                  zipcodePlaceholder="Choose a location"
                  pspsEvents={pspsEvents}
                  showStationsByPowerLevel
                  showLegendCard={false}
                  hideStations
                  userLocation={userLocation}
                  btnStyles={{ width: '100%' }}
                  footerText=""
                />
              </TabPane>
            )}
          </TabContent>
          {!isPage && (
            <div>
              <ChargingStationsList
                chargingStations={powerFilteredChargingStations}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool,
};

export default TabbedMaps;
