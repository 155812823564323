import React from "react";
import PropTypes from 'prop-types';
import "./FAQCard.scss"



const FAQCard = ({
    title,
    body,
    list,
    image,
    className,
}) => {
    return (
      <div className={"FAQCard " + className}>
        <section className="sideBySide">
          <div className="row container">
            <div className="text">
              <h2 className="text-title">{title}</h2>
              <div className="small-space">
                <p>{body}</p>
              </div>
              {list ? <ol className="list small-space">
                {list.map((item) => (
                  <li>{item}</li>
                ))}
              </ol> : null }
            </div>
            <div className="image">
              <img src={image} alt="faqImage" className="icon" />
            </div>
          </div>
        </section>
      </div>
    );
}

export default FAQCard;

FAQCard.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  list: PropTypes.array,
  image: PropTypes.any,
};