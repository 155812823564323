import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';


const HomepageBigPromise = () => {

  return (
    <section className="white">
      <div className="homepage-jumbotron">
        <h1>
          <FormattedMessage
            id="homepage.banner.title.text"
            defaultMessage="Drive electric."
            description="Drive electric. "
          />
        </h1>
        <h2>
          <FormattedMessage
            id="homepage.banner.subtitle.text"
            defaultMessage="Your guide to estimating and comparing costs, savings, incentives, and more."
            description="Your guide to estimating and comparing costs, savings, incentives, and more. "
          />
        </h2>
        <Link to="/vehicles" className="btn btn-pseg" role="button" style={{width: "270px", height: '54px', marginTop: '24px'}}>
            <FormattedMessage
              id="homepage.vehicleCarousel.compareVehiclesButton"
              defaultMessage="Find Your Electric Vehicle"
              description="Find Your Electric Vehicle"
            />
        </Link>

      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
  userLocation: PropTypes.string,
};
