import React from "react";

import "./styles.scss";

const RLMSDetailsNC = () => {
  return (
    <div>
      <h2 className="title">Standard Residential Service:</h2>

      <h3>Rate Overview</h3>
      <p className="text">
        NC Schedule 1 is the standard, default electric rate for residential customers. Customers are charged the same amount for electricity regardless of when they use it. 
      </p>
    </div>
  );
};

export default RLMSDetailsNC;