import React, { useContext } from 'react';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';

import './RateDetails.scss';

const RateDetails = ({ rates }) => {
  const userPrefs = useContext(UserPrefsContext);

  const translateId = (id) => {
    if(id === 'standard-vi') return "standard VI"
    if(id === 'standard-nc') return 'standard NC'
    if(id === "offPeak") return "offPeak"
    return id
  }

  const selectedRateDetailsId = translateId(userPrefs.get('selectedRateDetailsId'));
  const selectedRate = rates.find((rate) => rate.id === selectedRateDetailsId);
  const SelectedDetailsComponent =
    (selectedRate || {}).detailsComponent || (() => null);

  const getName = (rate) => {
    return `${rate.title} ${rate.subTitle}`;
  };

  const getRateLink = (rateId) => {
    switch (rateId) {
      case 'offPeak':
        return "https://www.dominionenergy.com/virginia/rates-and-tariffs/off-peak-plan";
      case 'standard VI':
        return "https://cdn-dominionenergy-prd-001.azureedge.net/-/media/pdfs/virginia/residential-rates/schedule-1.pdf?la=en&rev=418fcae22c2c4dfca1d844f3d1c5690b&hash=24094908FF111143A649A4C43DB4DF35";
      case 'standard NC':
        return "https://www.dominionenergy.com/-/media/pdfs/north-carolina---electric/residential-rates/nc-schedule-1.pdf?rev=3df3b8e2b7bd43e3a377c478eef62515";
      default:
        return "#"; 
    }
  };

  return (
    <div className="chart-details-container">
      <div>
        <div className="d-flex mb-4 chart-details-button-container">
          {rates.map((rate) => {
            return (
              <button
                className={`btn chartBtn-${
                  rate.id === selectedRateDetailsId ? 'ae' : 'secondary'
                } mr-2`}
                key={rate.id}
                onClick={() =>
                  userPrefs.set({ selectedRateDetailsId: rate.id })
                }
              >
                {getName(rate)}
              </button>
            );
          })}
        </div>
        <div>
          <SelectedDetailsComponent />
          <a
            href={getRateLink(selectedRateDetailsId)}
            className="cta-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
};

export default RateDetails;
