import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SelectVehicleMakeFilter from '../../../components/InputComponents/SelectVehicleMakeFilter/SelectVehicleMakeFilter';
import SelectVehicleModelFilter from '../InputComponents/SelectVehicleModelFilter/SelectVehicleModelFilter';
import SelectVehicleTrimFilter from '../InputComponents/SelectVehicleTrimFilter/SelectVehicleTrimFilter';

import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';

import { FormattedMessage } from 'react-intl';

const IncentiveVehicleOptions = ({ electricVehicles }) => {
  const [vehicleAge, setVehicleAge] = useState('new')
  const userPrefs = useContext(UserPrefsContext);

  useEffect(() => {
    userPrefs.set({vehicleCondition: vehicleAge})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[vehicleAge])

  return (
    <div className="input-well MatchScoreOptions">
      <div style={{ display: 'flex' }}>
        <p className="h2">
          <FormattedMessage
            id="evs.incentivesVehicleOptions"
            defaultMessage="Electric Vehicle Considered"
            description="EV Considered"
          />
        </p>
      </div>

      <div className='age-container'>
        <p>AGE</p>
        <div className='btn-container'>
          <button 
            onClick={() => setVehicleAge('new')} 
            className={vehicleAge === 'new' && 'active'}
          >
            New
          </button>
          <button 
            onClick={() => setVehicleAge('used')}
            className={vehicleAge === 'used' && 'active'}  
          >
            Used
          </button>
        </div>

      </div>
      <SelectVehicleMakeFilter vehicles={electricVehicles} />
      <SelectVehicleModelFilter
        vehicles={electricVehicles}
        makeOption={userPrefs.get('vehicleMakeFilter')}
      />
      <SelectVehicleTrimFilter
        vehicles={electricVehicles}
        makeOption={userPrefs.get('vehicleMakeFilter')}
        modelOption={userPrefs.get('vehicleModelFilter')}
      />
    </div>
  );
};

export default IncentiveVehicleOptions;

IncentiveVehicleOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
