import React from 'react';
import { FormattedMessage } from 'react-intl';
import chart1 from '../client_customizations/assets/images/faqs/chart-1.svg';
import chart2 from '../client_customizations/assets/images/faqs/chart-2.svg';
import chart3 from '../client_customizations/assets/images/faqs/chart-3.svg';
import chart4 from '../client_customizations/assets/images/faqs/chart-4.svg';
import chart5 from '../client_customizations/assets/images/faqs/chart-5.svg';
import chart6 from '../client_customizations/assets/images/faqs/chart-6.svg';
import chart7 from '../client_customizations/assets/images/faqs/chart-7.svg';
import chart8 from '../client_customizations/assets/images/faqs/chart-8.png';

export const fetchFaqs = async (params) => {
  const faqs = {
    ElecticVehicles: {
      title: 'Electric Vehicles',
      faqs: [
        {
          page: 'faqPage',
          section: 'Electric Vehicles',
          articleTitle: 'What is an electric vehicle (EV)?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.electricVehicles.faq1.paragraph1"
                  defaultMessage="Every EV has a battery pack that uses electricity to power an electric motor, which turns the wheels to make the car move. There are two main categories of electric vehicles:"
                  description=""
                />
              </p>

              <div class="img-2-col">
                    <div class="img-col">
                         <img src={chart1} alt="" className="align-center" />
                    </div>
                    <div class="img-col">
                         <img src={chart2} alt="" className="align-center" />
                    </div>
              </div>

              <p>
                <FormattedMessage
                  id="FAQ.electricVehicles.faq1.paragraph2"
                  defaultMessage="{batteryElectricVehicles} have a battery and an electric motor instead of a gas tank and internal combustion engine. They run entirely on electricity and can be plugged in to charge when the battery is depleted."
                  description=""
                  values={{
                    batteryElectricVehicles: (
                      <strong>
                        <FormattedMessage
                          id="FAQ.electricVehicles.faq1.batteryElectricVehicles"
                          defaultMessage="Battery Electric Vehicles"
                          description="Battery Electric Vehicles"
                        />
                      </strong>
                    ),
                  }}
                  f
                />
              </p>

              <p>
                <FormattedMessage
                  id="FAQ.electricVehicles.faq1.paragraph3"
                  defaultMessage="{plugInHybridElectricVehicles} (PHEVs) have an electric motor and a gas-powered internal combustion engine. PHEVs can operate on electric battery power until the battery is depleted, then the gas-powered engine turns on. PHEVs can also be plugged in to charge the battery when the vehicle is not in use. "
                  description=""
                  values={{
                    plugInHybridElectricVehicles: (
                      <strong>
                        <FormattedMessage
                          id="FAQ.electricVehicles.faq1.plugInHybridElectricVehicles"
                          defaultMessage="Plug-In Hybrid Electric Vehicles"
                          description="Plug-In Hybrid Electric Vehicles"
                        />
                      </strong>
                    ),
                  }}
                  f
                />
              </p>
            </>
          ),
        },
        {
          page: 'faqPage',
          section: 'Electric Vehicles',
          articleTitle: 'How far can I drive in an EV?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.electricVehicles.faq2.paragraph1"
                  defaultMessage="Electric driving range refers to the number of miles an EV can travel before the battery needs to be recharged. It can vary significantly between models, with most EVs having a driving range of 80 to 300+ miles on a single charge. The majority of Americans drive less than 40 miles per day on average, making even low- or mid-range EVs more than sufficient to meet typical daily driving needs."
                  description=""
                />
              </p>
              <p>
                <FormattedMessage
                  id="FAQ.electricVehicles.faq2.paragraph2"
                  defaultMessage="Going on a longer trip? {locatorMapLink} in many areas, and mobile apps can help you plan for charging on your route in advance."
                  description=""
                  values={{
                    locatorMapLink: (
                      <a href="/charging-stations">
                        <FormattedMessage
                          id="FAQ.electricVehicles.faq2.locatorMapLink"
                          defaultMessage="Public charging stations are available"
                          description=""
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </>
          ),
        },
        {
          page: 'faqPage',
          section: 'Electric Vehicles',
          articleTitle: 'What are the typical maintenance costs for an EV?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.electricVehicles.faq3.paragraph1"
                  defaultMessage="Electric vehicles typically have lower routine maintenance requirements than gas-powered cars because they have fewer fluids and moving parts."
                  description=""
                />
              </p>
              <p>
                <FormattedMessage
                  id="FAQ.electricVehicles.faq3.paragraph2"
                  defaultMessage="With a battery electric vehicle, there's no oil to change, no spark plugs or timing belts to replace, and no mufflers or catalytic converters to repair. Also, because they have no tailpipe emissions, you won't need to take it in for emissions testing. Brake systems also last longer due to a unique EV feature known as regenerative braking, which slows down the car when you take your foot off the accelerator, allowing you to apply the brakes less frequently."
                  description=""
                />
              </p>
            </>
          ),
        },
        {
          page: 'faqPage',
          section: 'Electric Vehicles',
          articleTitle: 'How long do EV batteries last?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.electricVehicles.faq4.paragraph1"
                  defaultMessage="Replacing an EV battery can be expensive, but you’re unlikely to have to pay to replace it. Federal regulations mandate that an EV battery be under warranty by the auto manufacturer for at least eight years or 100,000 miles."
                  description=""
                />
              </p>
              <p>
                <FormattedMessage
                  id="FAQ.electricVehicles.faq4.paragraph2"
                  defaultMessage="The industry for reusing and recycling EV batteries is growing. Batteries that are no longer able to reliably power an EV efficiently may still have around 80% of their original storage capacity, meaning they can still be used for things like intermittent renewable energy generation and storage at EV charging sites."
                  description=""
                />
              </p>
            </>
          ),
        },
        {
          page: 'faqPage',
          section: 'Electric Vehicles',
          articleTitle: 'Are EVs better for the environment?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.electricVehicles.faq5.paragraph1"
                  defaultMessage="Yes! EVs produce no tailpipe emissions and are much more energy efficient than gas-powered cars. Although the electricity used to power the battery may have been generated using fossil fuels, EVs show significant reductions in overall carbon emissions compared to gasoline vehicles. This is due to the highly carbon-intensive process of mining, pumping, refining, and transporting fossil fuels. On average, an EV produces less than half the total emissions of a comparable gas-powered car over the course of its life."
                  description=""
                />
              </p>
              <p>
                <FormattedMessage
                  id="FAQ.electricVehicles.faq5.paragraph2"
                  defaultMessage="Check out our {emissionsPowerMixTool} to estimate and compare your CO2 savings."
                  description=""
                  values={{
                    emissionsPowerMixTool: (
                      <a href="/emissions">
                        <FormattedMessage
                          id="FAQ.electricVehicles.faq5.emissionsPowerMixTool"
                          defaultMessage="Emissions & Power Mix tool"
                          description="Emissions & Power Mix tool"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </>
          ),
        },
      ],
    },
    Charging: {
      title: 'Charging',
      faqs: [
        {
          page: 'faqPage',
          section: 'Charging',
          articleTitle: 'How does EV charging work?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq1.paragraph1"
                  defaultMessage="Charging your EV is as simple as charging your phone - simply plug your vehicle in to a charger that's connected to the electric grid and continue with your day. There are three major categories of chargers:"
                  description=""
                />
              </p>
              <div className="faq-col-3">
                <div className="faq-col">
                  <img src={chart3} alt="" className="align-center" />
                  <p className="centered-text">
                    <b>
                      <FormattedMessage
                        id="FAQ.charging.faq1.level1"
                        defaultMessage="Level 1"
                        description=""
                      />
                    </b>
                  </p>
                  <ul>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level1.list1"
                          defaultMessage="Provides charging through a 120V AC plug (standard wall outlet)"
                          description=""
                        />
                      </span>
                    </li>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level1.list2"
                          defaultMessage="Delivers up to 5 miles of driving range per hour of charging"
                          description=""
                        />
                      </span>
                    </li>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level1.list3"
                          defaultMessage="Does not require installation of additional charging equipment"
                          description=""
                        />
                      </span>
                    </li>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level1.list4"
                          defaultMessage="Best used in home applications for overnight charging and low-mileage daily driving"
                          description=""
                        />
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="faq-col">
                  <img src={chart4} alt="" className="align-center" />
                  <p className="centered-text">
                    <b>
                      <FormattedMessage
                        id="FAQ.charging.faq1.level2"
                        defaultMessage="Level 2"
                        description=""
                      />
                    </b>
                  </p>
                  <ul>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level2.list1"
                          defaultMessage="Provides charging through a 240V AC plug (dryer outlet)"
                          description=""
                        />
                      </span>
                    </li>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level2.list2"
                          defaultMessage="Delivers up to 60 miles of driving range per hour of charging"
                          description=""
                        />
                      </span>
                    </li>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level2.list3"
                          defaultMessage="Requires installation of additional charging equipment"
                          description=""
                        />
                      </span>
                    </li>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level2.list4"
                          defaultMessage="Best used for public charging, workplace charging, or faster overnight charging at home"
                          description=""
                        />
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="faq-col">
                  <img src={chart5} alt="" className="align-center" />
                  <p className="centered-text">
                    <b>
                      <FormattedMessage
                        id="FAQ.charging.faq1.level3"
                        defaultMessage="DC Fast Charging"
                        description=""
                      />
                    </b>
                  </p>
                  <ul>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level3.list1"
                          defaultMessage="Provides charging through 480V input"
                          description=""
                        />
                      </span>
                    </li>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level3.list2"
                          defaultMessage="Delivers up to 80% battery charge in about 20-30 minutes of charging"
                          description=""
                        />
                      </span>
                    </li>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level3.list3"
                          defaultMessage="Requires specialized, high-powered charging equipment"
                          description=""
                        />
                      </span>
                    </li>
                    <li>
                      <span>
                        <FormattedMessage
                          id="FAQ.charging.faq1.level3.list4"
                          defaultMessage="Best used for public charging, especially along heavy traffic corridors"
                          description=""
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          ),
        },
        {
          page: 'faqPage',
          section: 'Charging',
          articleTitle:
            'How much does it cost to charge an EV? Will my electric bill go up?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq2.paragraph1"
                  defaultMessage="The cost of charging your EV depends on how much you drive. For customers who drive less than 40 miles a day, it costs about $1 a day to charge your EV at home. Your electric bill will likely go up, but your monthly gasoline expenses should drop considerably more."
                  description=""
                />
              </p>
              {/* <p>
                <FormattedMessage
                  id="FAQ.charging.faq2.paragraph2"
                  defaultMessage="To compare and estimate how EV charging will impact your bill, check out our {rateAdviserTool}."
                  description=""
                  values={{
                    rateAdviserTool: (
                      <a href="/rates">
                        <FormattedMessage
                          id="FAQ.charging.faq2.rateAdviserTool"
                          defaultMessage="Rate Adivsor tool"
                          description="Rate Adivsor tool"
                        />
                      </a>
                    ),
                  }}
                />
              </p> */}
            </>
          ),
        },
        {
          page: 'faqPage',
          section: 'Charging',
          articleTitle: 'Where can I charge my EV?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq3.paragraph1"
                  defaultMessage="If you have a garage or driveway, you can charge your EV at home using a Level 1 cord and a standard outlet or by installing a Level 2 charger."
                  description=""
                />
              </p>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq3.paragraph2"
                  defaultMessage="If you live in a multi-family community that does not offer EV charging, discuss installation options with your property management or homeowners' association and take advantage of public charging in the meantime."
                  description=""
                />
              </p>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq3.paragraph3"
                  defaultMessage="If you're unable to charge at home, don't worry. Fast, convenient public charging stations are opening every day. Find a charger near you by using our { stationLocatorMap}."
                  description=""
                  values={{
                    stationLocatorMap: (
                      <a href="/charging-stations">
                        <FormattedMessage
                          id="FAQ.charging.faq3.stationLocatorMap"
                          defaultMessage="station locator map"
                          description=""
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </>
          ),
        },
        {
          page: 'faqPage',
          section: 'Charging',
          articleTitle: 'How can I install an EV charger at home?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq4.paragraph1"
                  defaultMessage="If you'd like to install a Level 2 charger at your home, you have several options:"
                  description=""
                />
              </p>
              <ul>
                <li>
                  <span>
                    <FormattedMessage
                      id="FAQ.charging.faq4.list1"
                      defaultMessage="Talk to your dealer or the vehicle manufacturer to determine your charging options and equipment."
                      description=""
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <FormattedMessage
                      id="FAQ.charging.faq4.list2"
                      defaultMessage="Contact a qualified electrician for personalized charging options, equipment, installation, and any required permitting for your specific project."
                      description=""
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <FormattedMessage
                      id="FAQ.charging.faq4.list3"
                      defaultMessage="Dominion Energy offers turn-key charging solution through our {residentialChargingProgramLink}. Submit an {interestFormLink} to learn more."
                      description=""
                      values={{
                        residentialChargingProgramLink: (
                          <a href="https://www.dominionenergy.com/virginia/save-energy/electric-vehicles/residential-charger-program">
                            <FormattedMessage
                              id="FAQ.charging.faq4.residentialChargingProgramLink"
                              defaultMessage="Residential Charging Program"
                              description=""
                            />
                          </a>
                        ),
                        interestFormLink: (
                          <a href="https://dominionenergycrm.my.site.com/EVInterestForm/s/">
                            <FormattedMessage
                              id="FAQ.charging.faq4.interestFormLink"
                              defaultMessage="interest form"
                              description=""
                            />
                          </a>
                        ),
                      }}
                    />
                  </span>
                </li>
              </ul>
            </>
          ),
        },
        {
          page: 'faqPage',
          section: 'Charging',
          articleTitle: 'Which plug types can I use to charge my EV?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq5.paragraph1"
                  defaultMessage="The type of EV plug or connector you will need to charge your vehicle depends on your vehicle manufacturer and the level at which you'd like to charge."
                  description=""
                />
              </p>

              <div class="img-2-col">
                    <div class="img-col">
                         <img src={chart6} alt="" className="align-center" />
                    </div>
                    <div class="img-col">
                         <img src={chart7} alt="" className="align-center" />
                    </div>
              </div>

              <p>
                <FormattedMessage
                  id="FAQ.charging.faq5.paragraph2"
                  defaultMessage="The {J1772} (often referred to simply as J1772) is an industry standard connector that plugs into an EV's charge port and provides power for Level 1 and Level 2 charging. In most cases, if you're driving an EV in North America, you can connect to a home or public charging station using this type of plug."
                  description=""
                  values={{
                    J1772: (
                      <strong>
                        <FormattedMessage
                          id="FAQ.charging.faq5.J1772"
                          defaultMessage="SAE J1772"
                          description=""
                        />
                      </strong>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq5.paragraph3"
                  defaultMessage="The {J3400} (also referred to as NACS, or North American Charging Standard) is a connector mostly associated with Tesla vehicles that can be used for all charging levels. "
                  description=""
                  values={{
                    J3400: (
                      <strong>
                        <FormattedMessage
                          id="FAQ.charging.faq5.J3400"
                          defaultMessage="J3400"
                          description=""
                        />
                      </strong>
                    ),
                  }}
                />
              </p>

              <p>
                <FormattedMessage
                  id="FAQ.charging.faq5.paragraph4"
                  defaultMessage="The {CCS} connector (also known as SAE J1772 Combined Charging System) lets drivers use the same charging port with AC Level 1, Level 2, and DC fast charging equipment. The only difference is that the CCS connector has two additional bottom pins to enable faster charging."
                  description=""
                  values={{
                    CCS: (
                      <strong>
                        <FormattedMessage
                          id="FAQ.charging.faq5.CCS"
                          defaultMessage="CCS"
                          description=""
                        />
                      </strong>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq5.paragraph5"
                  defaultMessage="The {CHAdeMO} connector is another DC fast charging connector, commonly used in Japanese EVs."
                  description=""
                  values={{
                    CHAdeMO: (
                      <strong>
                        <FormattedMessage
                          id="FAQ.charging.faq5.CHAdeMO"
                          defaultMessage="CHAdeMO"
                          description=""
                        />
                      </strong>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq5.paragraph6"
                  defaultMessage="Charging infrastructure is still evolving. Automakers are gradually adopting standardized connectors and, in the meantime, some EVs have adapters available so you can plug in to any charger. When shopping for your EV, be sure to check the vehicle specs to see what kind of connector your new car will need."
                  description=""
                />
              </p>
            </>
          ),
        },
        {
          page: 'faqPage',
          section: 'Charging',
          articleTitle: 'What is EV charging infrastructure?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq6.paragraph1"
                  defaultMessage="The term EV charging infrastructure includes all of the equipment and systems required to charge an electric vehicle. While there are three main categories of EV charging infrastructure, for most home charger installations, you will only need to worry about the charger itself (EVSE). Dominion Energy and your electrician will help you figure out the rest."
                  description=""
                />
              </p>
              <ol>
                <li>
                  <span>
                    <FormattedMessage
                      id="FAQ.charging.faq6.list1"
                      defaultMessage="Utility infrastructure - this includes all of the utility equipment up to the electric meter, including substations, transformers, and distribution lines."
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <FormattedMessage
                      id="FAQ.charging.faq6.list2"
                      defaultMessage="Electric vehicle supply infrastructure (EVSI) - this includes all of the electric equipment from the electric meter to the location where the charging stations will be installed, including switchgear, electric panels, conduits, and wiring. This infrastructure is often referred to as “EV make-ready” because it is making the site ready for the EV chargers."
                    />
                  </span>
                </li>
                <li>
                  <span>
                    <FormattedMessage
                      id="FAQ.charging.faq6.list3"
                      defaultMessage="Electric vehicle supply equipment (EVSE) - these are the EV chargers themselves. Public chargers can have 1 or 2 charging ports each."
                    />
                  </span>
                </li>
              </ol>
              <img src={chart8} alt="" className="align-center" />
            </>
          ),
        },
        {
          page: 'faqPage',
          section: 'Charging',
          articleTitle: 'What other EV infrastructure programs does Dominion Energy offer?',
          articleBody: (
            <>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq7.paragraph1"
                  defaultMessage="Dominion Energy's {levelTwoProgramLink} provides a turn-key solution for Level 2 EV charging infrastructure. Through the program, we will install and maintain level 2 charging stations for your business, parking area, or multi-family community. The program provides incentives for the EV charging station make-ready and on-bill payment options for the remaining costs."
                  description=""
                  values={{
                    levelTwoProgramLink: (
                      <a href="https://www.dominionenergy.com/virginia/save-energy/electric-vehicles/charging-on-the-go/level-2-charging-program">
                        <FormattedMessage
                          id="FAQ.charging.faq7.levelTwoProgramLink"
                          defaultMessage="Level 2 Charging Program"
                          description=""
                        />
                      </a>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq7.paragraph2"
                  defaultMessage="The {fleetChargingProgramLink} provides a turn-key solution for the installation and maintenance of Level 2 or DCFC EV charging stations for fleet customers. The program also provides advisory services to help with fleet electrification planning, incentives for the EV charging station make-ready, and convenient, on-bill payments for the remaining costs."
                  description=""
                  values={{
                    fleetChargingProgramLink: (
                      <a href="https://www.dominionenergy.com/virginia/save-energy/electric-vehicles/charging-your-fleet/fleet-charging-program">
                        <FormattedMessage
                          id="FAQ.charging.faq7.fleetChargingProgramLink"
                          defaultMessage="Fleet Charging Program"
                          description=""
                        />
                      </a>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="FAQ.charging.faq7.paragraph3"
                  defaultMessage="The {environmentalJusticeLink} aims to promote equitable access to electric vehicle (EV) charging infrastructure within Environmental Justice Communities by providing no-cost EV charging infrastructure installation and maintenance for fleet, public, workplace, and multi-family charging."
                  description=""
                  values={{
                    environmentalJusticeLink: (
                      <a href="https://www.dominionenergy.com/virginia/save-energy/electric-vehicles/ejc-program">
                        <FormattedMessage
                          id="FAQ.charging.faq7.environmentalJusticeLink"
                          defaultMessage="Environmental Justice Community (EJC) EV Charging Program"
                          description=""
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </>
          ),
        },
      ],
    },
  };
  return faqs;
};

export default fetchFaqs;
