import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FaqComponent from '../../components/Faq/FaqComponent';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import faqImage1 from '../../client_customizations/assets/images/faqImage1.png';
import faqImage2 from '../../client_customizations/assets/images/faqImage2.png';
import faqImage3 from '../../client_customizations/assets/images/faqImage3.png';
import FAQCard from '../../client_customizations/components/FAQCard/FAQCard';
import './FAQ.scss';

const FAQ = ({ faqs }) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_FAQ_TITLE;
  });

  if (!faqs)
    return (
      <div className="spinner-container">
        <LoadingSpinner />
      </div>
    );

  console.log(faqs);

  return (
    <div className="FAQ">
      <section
        className="container white nopadding-top"
        style={{ paddingBottom: '0' }}
      >
        <div className="row">
          <div className="faq-jumbotron">
            <h1 className="title-faq pad-top">
              <FormattedMessage
                id="FAQ.title.v2"
                defaultMessage="Electric Vehicles 101"
                description="Electric Vehicles 101"
              />
            </h1>
            <h2 className="subtitle-faq mb-bottom pad-bottom">
              <FormattedMessage
                id="FAQ.subtitle"
                defaultMessage="EVs are still new, and it's natural to have questions. Find answers below."
                description="EVs are still new, and it's natural to have questions. Find answers below."
              />
            </h2>
          </div>
        </div>
      </section>
      <section>
        <FAQCard
          title={
            <FormattedMessage
              id="FAQ.sectionTitleOne"
              defaultMessage="Your Questions Answered,"
              description="Your Questions Answered,"
            />
          }
          body={
            <FormattedMessage
              id="FAQ.bodyOne"
              defaultMessage="Electric vehicles (EVs) are an increasingly popular choice for drivers around the country. Not only are they becoming more economical to own and operate, but they can also help reduce your carbon footprint. Dominion Energy is driving a clean energy future for our customers and communities. In addition to our suite of tools and programs designed to make your transition to electric easy, our team of EV experts has delivered this resource to help answer your questions so you can be confident you're making the decision that's right for you. {lineBreak} Dominion Energy is driving a clean energy future for our customers and communities. In addition to our suite of tools and programs designed to make your transition to electric easy, our team of EV experts has delivered this resource to help answer your questions so you can be confident you're making the decision that's right for you."
              description=""
              values={{
                lineBreak: (
                  <p style={{ margin: '5px' }}>
                    <br />
                  </p>
                ),
              }}
            />
          }
          image={faqImage1}
        />
        <FAQCard
          className="faq-card-reverse"
          title={
            <FormattedMessage
              id="FAQ.sectionTitleTwo"
              defaultMessage="Charging is Simple and Easy"
              description="Charging is Simple and Easy"
            />
          }
          body={
            <FormattedMessage
              id="FAQ.bodyTwo"
              defaultMessage="Charging your EV at home can be as easy as plugging into a standard wall outlet with the Level 1 charging cord that comes with your vehicle. For a faster recharge, consider installing a Level 2 home charger to help your EV reach a full charge overnight, every night. {lineBreak} To install a Level 2 charger at home, select your charger and connect with a professional electrician to complete your installation. Our Residential Charging Program provides a one-stop-shop to make the process easy and convenient. Fill out our {interestFormLink} to learn more. {lineBreak} With new charging stations opening every day, it's never been easier to charge on the go. Find public charging stations near you by using our {locatorMapLink}."
              description=""
              values={{
                lineBreak: (
                  <p style={{ margin: '5px' }}>
                    <br />
                  </p>
                ),
                interestFormLink: (
                  <a
                    href="https://dominionenergycrm.my.site.com/EVInterestForm/s/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    interest form
                  </a>
                ),
                locatorMapLink: (
                  <a
                    href="/charging-stations"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    locator map
                  </a>
                ),
              }}
            />
          }
          image={faqImage2}
        />
        <FAQCard
          title={
            <FormattedMessage
              id="FAQ.sectionTitleThree"
              defaultMessage="Benefits of Driving Electric"
              description="Benefits of Driving Electric"
            />
          }
          body={
            <FormattedMessage
              id="FAQ.bodyThree"
              defaultMessage="{fuelSavingsTitle} {lineBreak} On average, EVs can travel the same distance as a gas-powered car at less than half the cost. {lineBreak}{lineBreak} {lowMaintenanceTitle} {lineBreak} EVs have fewer fluids and parts than conventional vehicles, so they require minimal maintenance. {lineBreak}{lineBreak} {environmentalBenefitsTitle} {lineBreak} EVs produce no tailpipe emissions, making the air in your community cleaner. {lineBreak}{lineBreak} {safetyFunTitle} {lineBreak} EVs offer a quiet, smooth ride, instant acceleration, and numerous safety features to ensure that every trip is a good one."
              description=""
              values={{
                lineBreak: <br />,
                fuelSavingsTitle: (
                  <strong>
                    <FormattedMessage
                      id="FAQ.sectionTitleThree.FuelSavings"
                      defaultMessage="Fuel Savings"
                      description=""
                    />
                  </strong>
                ),
                lowMaintenanceTitle: (
                  <strong>
                    <FormattedMessage
                      id="FAQ.sectionTitleThree.LowMaintenance"
                      defaultMessage="Low Maintenance"
                      description=""
                    />
                  </strong>
                ),
                environmentalBenefitsTitle: (
                  <strong>
                    <FormattedMessage
                      id="FAQ.sectionTitleThree.EnvironmentalBenefits"
                      defaultMessage="Environmental Benefits"
                      description=""
                    />
                  </strong>
                ),
                safetyFunTitle: (
                  <strong>
                    <FormattedMessage
                      id="FAQ.sectionTitleThree.SafetyFun"
                      defaultMessage="Safety and Fun"
                      description=""
                    />
                  </strong>
                ),
              }}
            />
          }
          image={faqImage3}
        />
      </section>
      <section className="container">
        <div className="row faqContainerPage">
          {Object.values(faqs).map((section) => (
            <div className="faq-section" key={`faq-${section.title}`}>
              <h2>{section.title}</h2>
              <FaqComponent faqs={section.faqs} />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FAQ;

FAQ.propTypes = {
  faqs: PropTypes.array,
};
