import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleSection from '../../client_customizations/components/CollapsibleSection/CollapsibleSection';

import './FaqComponent.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const FaqComponent = ({ faqs }) => {
  if (!faqs)
    return (
      <div className="spinner-container">
        <LoadingSpinner />
      </div>
    );

  return (
    <div className="CollapsibleSectionContainer">
      {faqs.map((content, index) => (
        <CollapsibleSection
          key={`article-${content.section}-${index}`}
          headLine={content.articleTitle}
        >
          {content.articleBody}
        </CollapsibleSection>
      ))}
    </div>
  );
};

export default FaqComponent;
FaqComponent.propTypes = {
  faqs: PropTypes.array,
};
