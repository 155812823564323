import React from 'react';

import './styles.scss';

const RSDetails = () => {
  return (
    <div>
      <h2 className="title">Off-Peak Plan:</h2>

      <div>
        <p className="text">
          The Off-Peak Plan is a voluntary, whole-house time-of-use rate
          schedule available to residential customers who have a smart meter at
          their home.
        </p>
        <a
          href="https://www.dominionenergy.com/virginia/rates-and-tariffs/off-peak-plan"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          More Info{' '}
        </a>
        . 
      </div>
      <br />
      <p className="text">
        Under this plan, you will be charged different amounts for the
        electricity you use depending on when you use it throughout the day. The
        Plan has two seasonal structures with three tiers of pricing based on
        when energy is most in demand: on-peak, off-peak, and super off-peak
        time periods.
      </p>
      <br />
      <p className="text">
        Costs are lowest during Super Off-Peak hours (12am to 5am), everyday
        including weekends and holidays when demand is lowest, making this the
        best time to charge your vehicle. 
      </p>

      <h2 className="title">Customers who may benefit:</h2>
      <p className="text">
        This rate plan works for customers who have an electric vehicle (EV)
        and/or battery storage and can charge during off-peak and super off-peak
        hours, in addition to shifting other household energy usage to off-peak
        and super off-peak hours. Households that have their highest electricity
        use in the summer typically perform better. 
      </p>
    </div>
  );
};

export default RSDetails;
