const boolDefault = (val) => (typeof val === 'boolean' ? val : null);

const loadUserPrefs = (savedPrefs) => {
  return {
    canTurnInClunker: boolDefault(savedPrefs.canTurnInClunker),
    chargerInstallAllowed: boolDefault(savedPrefs.chargerInstallAllowed),
    chargerLevel: savedPrefs.chargerLevel || null,
    electricMilesPortionForPhev: savedPrefs.electricMilesPortionForPhev || null,
    hasOvernightCharger: boolDefault(savedPrefs.hasOvernightCharger),
    gasolinePriceInCentsPerGal: savedPrefs.gasolinePriceInCentsPerGal || null,
    hasRegularPowerOutlet: boolDefault(savedPrefs.hasRegularPowerOutlet),
    householdIncome: savedPrefs.householdIncome || null,
    householdSize: savedPrefs.householdSize || null,
    includeResaleValue: boolDefault(savedPrefs.includeResaleValue),
    interestRateAsBasisPoints: savedPrefs.interestRateAsBasisPoints || null,
    locallyAvailableVehicleFilter: boolDefault(
      savedPrefs.locallyAvailableVehicleFilter
    ),
    maxBudget: savedPrefs.maxBudget || null,
    milesDrivenAnnually: savedPrefs.milesDrivenAnnually || null,
    milesDrivenDaily: savedPrefs.milesDrivenDaily || null,
    minSeats: savedPrefs.minSeats || null,
    monthsOfOwnership: savedPrefs.monthsOfOwnership || null,
    parkingLocation: savedPrefs.parkingLocation || null,
    purchaseMethod: savedPrefs.purchaseMethod || null,
    showAllRates: boolDefault(savedPrefs.showAllRates),
    showGasolineCost: boolDefault(savedPrefs.showGasolineCost),
    showChargerCost: boolDefault(savedPrefs.showChargerCost),
    showHomeElectricityCost: boolDefault(savedPrefs.showHomeElectricityCost),
    showRateDetails: boolDefault(savedPrefs.showRateDetails),
    showDetailsForRate: savedPrefs.showDetailsForRate || null,
    taxFilingStatus: savedPrefs.taxFilingStatus || null,
    vehicleChargingPattern: savedPrefs.vehicleChargingPattern || null,
    vehicleIdForIncentives: savedPrefs.vehicleIdForIncentives || null,
    vehicleHandleForIncentives: savedPrefs.vehicleHandleForIncentives || null,
    vehicleSortDirection: savedPrefs.vehicleSortDirection || null,
    vehicleSortType: savedPrefs.vehicleSortType || null,
    workingZipcode: savedPrefs.zipcode || null,
    zipcode: savedPrefs.zipcode || null,
    chargerBudget: savedPrefs.chargerBudget || null,
    chargerCordLength: savedPrefs.chargerCordLength || null,
    chargerSortType: savedPrefs.chargerSortType || null,
    chargerSortDirection: savedPrefs.chargerSortDirection || null,
    vehicleMakeFilter: savedPrefs.vehicleMakeFilter || null,
    vehicleModelFilter: savedPrefs.vehicleModelFilter || null,

    vehicleCondition: savedPrefs.vehicleCondition || null,
    vehicleYearStartFilter: savedPrefs.vehicleYearStartFilter || null,
    vehicleYearEndFilter: savedPrefs.vehicleYearEndFilter || null,
    vehicleDistanceFilter: savedPrefs.vehicleDistanceFilter || null,
    vehicleSelectedDealer: savedPrefs.vehicleSelectedDealer || null,

    vehicleInsuranceYearly: savedPrefs.vehicleInsuranceYearly || null,

    vehicleAgeFilter: savedPrefs.vehicleAgeFilter || null,
    electricityRate: savedPrefs.electricityRate || null,
    salesTax: savedPrefs.boo || null,
    eventLocation: savedPrefs.eventLocation || null,
    eventsSortType: savedPrefs.eventsSortType || null,

    vehicleFuelTypeFilters: {
      bev: boolDefault(savedPrefs.vehicleFuelTypeFilters.bev),
      phev: boolDefault(savedPrefs.vehicleFuelTypeFilters.phev),
    },

    vehicleFormFactorFilters: {
      sedan: boolDefault(savedPrefs.vehicleFormFactorFilters.sedan),
      hatchback: boolDefault(savedPrefs.vehicleFormFactorFilters.hatchback),
      coupe: boolDefault(savedPrefs.vehicleFormFactorFilters.coupe),
      crossover: boolDefault(savedPrefs.vehicleFormFactorFilters.crossover),
      minivan: boolDefault(savedPrefs.vehicleFormFactorFilters.minivan),
      wagon: boolDefault(savedPrefs.vehicleFormFactorFilters.wagon),
      truck: boolDefault(savedPrefs.vehicleFormFactorFilters.truck),
      suv: boolDefault(savedPrefs.vehicleFormFactorFilters.suv),
    },
    chargerWifiFilter: {
      yes: boolDefault(savedPrefs.chargerWifiFilter.yes),
      no: boolDefault(savedPrefs.chargerWifiFilter.no),
    },

    chargerTypeFilters: {
      mounted: boolDefault(savedPrefs.chargerTypeFilters.mounted),
      portable: boolDefault(savedPrefs.chargerTypeFilters.portable),
    },

    chargerFormFactorFilters: {
      '6-20': boolDefault(savedPrefs.chargerFormFactorFilters['6-20']),
      '6-30': boolDefault(savedPrefs.chargerFormFactorFilters['6-30']),
      '10-30': boolDefault(savedPrefs.chargerFormFactorFilters['10-30']),
      '14-30': boolDefault(savedPrefs.chargerFormFactorFilters['14-30']),
      '6-50': boolDefault(savedPrefs.chargerFormFactorFilters['6-50']),
      '14-50': boolDefault(savedPrefs.chargerFormFactorFilters['14-50']),
      '14-60': boolDefault(savedPrefs.chargerFormFactorFilters['14-60']),
      Hardwired: boolDefault(savedPrefs.chargerFormFactorFilters['Hardwired']),
    },

    selectedEnergyCostIds: savedPrefs.selectedEnergyCostIds,
    chargerPaymentType: savedPrefs.chargerPaymentType,
    chargingLocation: savedPrefs.chargingLocation,
    electricPanelLocation: savedPrefs.electricPanelLocation,
    electricPanelLocationRoomType: savedPrefs.electricPanelLocationRoomType,
    panelDistanceToChargerInFeet: savedPrefs.panelDistanceToChargerInFeet,
    currentMonthlyBill: savedPrefs.currentMonthlyBill,
    publicChargingPercentage: savedPrefs.publicChargingPercentage,
    publicChargingCostInCents: savedPrefs.publicChargingCostInCents,
    selectedRateTypeIds: savedPrefs.selectedRateTypeIds,
    rateComparisonVehicleCondition: savedPrefs.rateComparisonVehicleCondition,
    usedVehicleIdForRateComparison: savedPrefs.usedVehicleIdForRateComparison,
    selectedRateDetailsId: savedPrefs.selectedRateDetailsId,

    isComparingLowestRateOnly: boolDefault(
      savedPrefs.isComparingLowestRateOnly
    ),
    vehicleIdForRateComparison: savedPrefs.vehicleIdForRateComparison,
    chargingPatternId: savedPrefs.chargingPatternId || 'ALL_HOURS',
    currentRateId: savedPrefs.currentRateId || 'offPeak',
    hasSolarAtHome: boolDefault(savedPrefs.hasSolarAtHome),
    publicChargingCostInCentsPerKwh:
      savedPrefs.publicChargingCostInCentsPerKwh || null,
    equivalentMilesPerGallon: savedPrefs.equivalentMilesPerGallon || null,
    currentRateClimateZone: savedPrefs.currentRateClimateZone || 'COASTAL',
    equivalentGasVehicleRateComparison:
      savedPrefs.equivalentGasVehicleRateComparison || null,
  };
};

export default loadUserPrefs;
