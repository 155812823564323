import React, { useState } from 'react';
import './InputPanel.scss';

const InputPanel = ({
  children,
  title,
  isCollapsed: externalIsCollapsed,
  onClickCollapse,
  defaultIsCollapsed,
  className,
}) => {
  const isCollapsible = externalIsCollapsed !== undefined || defaultIsCollapsed !== undefined;
  const [localIsCollapsed, setLocalIsCollapsed] = useState(defaultIsCollapsed);

  const isCurrentlyCollapsed = () => {
    if (!isCollapsible) return false;
    if (externalIsCollapsed !== undefined) return externalIsCollapsed;
    return localIsCollapsed;
  }

  return (
    <div className={['InputPanel', className].filter(c => c).join(' ')}>
      <div className="heading">
        <div className="title">{title}</div>
        {isCollapsible && (
          <div className="collapser">
            <button
              aria-label="Collapse filter"
              type="button"
              className={`btn btn-link ${isCurrentlyCollapsed() ? 'collapsed' : ''}`}
              onClick={() => setLocalIsCollapsed(current => !current)}
            >
            </button>
          </div>
        )}
      </div>
      {!isCurrentlyCollapsed() && (
        children
      )}
    </div>
  );
};

export default InputPanel;
