import React from "react";
import PropTypes from "prop-types";
import "./RateButtonGroup.scss";
import { 
  FROM_12AM_TO_5AM,
  FROM_5AM_TO_3PM,
  FROM_3PM_TO_6PM,
  FROM_6PM_TO_12AM,
  FROM_5AM_TO_9AM,
  FROM_9AM_TO_5PM,
  FROM_5PM_TO_12AM,
  ALL_HOURS,
 } from "../../../client_customizations/constants";
import { useIntl } from 'react-intl';
import i12am5am from '../../../client_customizations/assets/images/hour-time-line.png'
import i5am3pm from '../../../client_customizations/assets/images/5am3pm.png'
import i3pm6pm from '../../../client_customizations/assets/images/3pm6pm.png'
import i6pm12am from '../../../client_customizations/assets/images/6pm12am.png'
import iallHours from '../../../client_customizations/assets/images/allhoursTimeline.png'
import i5am9am from '../../../client_customizations/assets/images/5am9am.png'
import i9am5pm from '../../../client_customizations/assets/images/9am5pm.png'
import i5pm12am from '../../../client_customizations/assets/images/5pm12am.png'


const RateButtonGroup = ({ id, value, handler, label, chargingPatterns }) => {
  const intl = useIntl();

  const renderButtons = () => {
    let top = [];
    let bottom = [];

    chargingPatterns.map((pattern, i) => {
      if (pattern.id === ALL_HOURS) {
        return top.push(
          <button
            key={i}
            className={`btn big-height ${value === pattern.id ? "active" : ""} large`}
            onClick={(e) => handler(e, pattern.id)}
          >
            <p className="title">{intl.formatMessage({ id: "modal13", defaultMessage: "All Hours" })}</p>
          </button>
        );
      }

      return bottom.push(
        <div className="wrapper">
          <button
            key={i}
            className={`btn big-height ${value === pattern.id ? "active" : ""}`}
            onClick={(e) => handler(e, pattern.id)}
          >
            <p className="title">{pattern.title}</p>
          </button>
        </div>
      );
    });

    return (
      <>
        <div className="d-flex justify-content-between">{bottom}</div>
        {top}
      </>
    );
  };

  const getImgSrc = () => {
    switch (value) {
      case FROM_12AM_TO_5AM:
        return i12am5am
      case FROM_5AM_TO_3PM:
        return i5am3pm
      case FROM_3PM_TO_6PM:
        return i3pm6pm
      case FROM_6PM_TO_12AM:
        return i6pm12am
      case FROM_5AM_TO_9AM:
        return i5am9am
      case FROM_9AM_TO_5PM:
        return i9am5pm
      case FROM_5PM_TO_12AM:
        return i5pm12am
      case ALL_HOURS:
        return iallHours
    
      default:
        break;
    }
  }

  return (
    <div className="form-group RateButtonGroup" id={id}>
      <span className="label-style">{label}</span>
      {renderButtons()}
      <img src={getImgSrc()} style={{marginTop: '24px'}} alt='Hour time line' />
    </div>
  );
};

export default RateButtonGroup;

RateButtonGroup.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  handler: PropTypes.func,
  optionNames: PropTypes.array,
  optionValues: PropTypes.array,
  label: PropTypes.string,
};
