import React from "react";
import PropTypes from "prop-types";

import ChargingPinPublic from "./../../../client_customizations/assets/images/icons/charger-pin-public.svg";
import ChargingPinHighPower from "./../../../client_customizations/assets/images/icons/charger-pin-high-power.svg";
import ChargingPinDefault from "./../../../client_customizations/assets/images/icons/charger-pin-private.svg";
import isSuperChargingStation from "../../../utils/predicates/isSuperChargingStation";
import { FormattedMessage } from "react-intl";

import "./ChargingStationsMapLegend.scss";

const ChargingStationsMapLegend = ({ chargingStations }) => {
  const hasSuperChargerStations = (chargingStations || []).some((station) =>
    isSuperChargingStation(station)
  );

  return (
    <div className="legend ChargingStationsMapLegend">
      {hasSuperChargerStations && (
        <div className="charger-type">
          <img src={ChargingPinHighPower} alt="" />
          <div className="text-wrapper">
            <b>
              <FormattedMessage
                id="chargingMap.highPowerStations"
                defaultMessage="High Power Stations"
                description="High Power Stations Text"
              />
            </b>
            <span>
              <FormattedMessage
                id="chargingMap.DCChargers"
                defaultMessage="DC fast Chargers"
                description="DC fast Chargers or superchargers Text"
              />
            </span>
          </div>
        </div>
      )}
      <div className="charger-type">
        <img src={ChargingPinPublic} alt="" />
        <div className="text-wrapper">
          <b>
            <FormattedMessage
              id="chargingMap.public"
              defaultMessage="Public Stations"
              description="Public Stations"
            />
          </b>
     
          <span>
            <FormattedMessage
              id="chargingMap.businessGovernment"
              defaultMessage="Level 2 chargers"
              description="Level 2 chargers"
            />
          </span>
        </div>
      </div>
      <div className="charger-type">
        <img src={ChargingPinDefault} alt="" />
        <div className="text-wrapper">
          <b>
            <FormattedMessage
              id="chargingMap.otherStations"
              defaultMessage="Other Stations"
              description="Other Types of Stations"
            />
          </b>
        
          <span>
            <FormattedMessage
              id="chargingMap.privateStations"
              defaultMessage="Private Stations"
              description="Private Stations"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

ChargingStationsMapLegend.propTypes = {
  chargingStations: PropTypes.array,
};

ChargingStationsMapLegend.defaultProps = {
  chargingStations: [],
};

export default ChargingStationsMapLegend;
