import React  from 'react';
import PropTypes from "prop-types";

import IncentivePreferences from "../IncentivePreferences/IncentivePreferences"

import SlideMilesDrivenDaily from "../../../components/InputComponents/SlideMilesDrivenDaily/SlideMilesDrivenDaily";
import SlideMaxBudget from "../../../components/InputComponents/SlideMaxBudget/SlideMaxBudget";
import SlideMinSeats from "../../../components/InputComponents/SlideMinSeats/SlideMinSeats";

import { FormattedMessage, useIntl } from "react-intl";
import EVFilterControls from "../EVFilterControls/EVFilterControls";
import EVFilterType from "./EVFilterType";
import EVFilterAvailableNow from "./EVFilterAvailableNow";

const MatchScoreOptions = ({ electricVehicles, location, mobile }) => {
  const intl = useIntl();



  return (
    <>
      <div className="input-well MatchScoreOptions">
        {!mobile ? (
          <p className="h3">
            <FormattedMessage
              id="evs.matchScoreOptions"
              defaultMessage="Refine Match Score"
              description="Refine Match Score"
            />
          </p>
        ) : null}

        <EVFilterControls vehicles={electricVehicles} />

        <SlideMaxBudget />
        <IncentivePreferences
          electricVehicles={electricVehicles}
          location={location}
          titleText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: 'personalizeIncentives',
                  defaultMessage: 'Personalize Incentives',
                })
              : 'Personalize Incentives'
          }
          btnText={
            intl.formatMessage
              ? intl.formatMessage({
                  id: 'personalizeIncentives',
                  defaultMessage: 'Personalize Incentives',
                })
              : 'Personalize Incentives'
          }
          btnSubtle
        />

        <SlideMilesDrivenDaily />

        <SlideMinSeats />

        <EVFilterType />

        <EVFilterAvailableNow />
      </div>
    </>
  );
};

export default MatchScoreOptions;

MatchScoreOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
