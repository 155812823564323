import React from 'react';
import PropTypes from 'prop-types';
import RenderAssumption from '../../components/RenderAssumption/RenderAssumption';
import ChargingRangeGraph from '../CostsCharts/ChargingRangeGraph';
import ChargingRangeGraphMobile from '../CostsCharts/ChargingRangeGraphMobile';
import { FormattedMessage } from 'react-intl';

const SpeedOfCharge = ({ cars, title }) => {
  if (!cars || cars.length === 0) return null;

  const level2ChargingSpeed = {
    title: 'Level 2 Charger Speed',
    valueAsFormatted: '7.7 kW',
  };

  const fastChargingSpeed = {
    title: 'Fast Charging Speed',
    valueAsFormatted: '50 kW',
  };

  return (
    <section id="speed-charge-chart">
      <div className="row">
        <div className="col-md-4 col-lg-3" style={{ textAlign: 'left' }}>
          <div className="input-well d-none d-md-block shadowed">
            <p className="RenderItemTitle left-align mb-10">
              <FormattedMessage
                id="graph.assumptions"
                defaultMessage="ASSUMPTION"
                description="Assumptions"
              />
            </p>
            <RenderAssumption assumption={level2ChargingSpeed} />
            <RenderAssumption assumption={fastChargingSpeed} />
          </div>
        </div>
        <div
          id="costs-chart-areas"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: 'absolute', top: '-9999px' }}
        >
          <span>
            <FormattedMessage
              id="results.updated"
              defaultMessage="Results have been updated as of "
              description="Results have been updated as of "
            />
            {new Date().toLocaleString()}.
          </span>
        </div>
        <div className="col-md-8 col-lg-9 d-none d-lg-block d-xl-block">
          <ChargingRangeGraph cars={cars} chargerLevel={'dc_fast_charger'} />
          <br />
          <ChargingRangeGraph cars={cars} chargerLevel={'level_2'} />
        </div>
        <div className="col-md-8 col-lg-9 d-lg-none d-xl-block-none">
          <ChargingRangeGraphMobile
            cars={cars}
            chargerLevel={'dc_fast_charger'}
          />
          <br />
          <ChargingRangeGraphMobile cars={cars} chargerLevel={'level_2'} />
        </div>
      </div>
    </section>
  );
};

export default SpeedOfCharge;

SpeedOfCharge.propTypes = {
  cars: PropTypes.array,
  title: PropTypes.string,
};
