import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TabbedMaps from '../../components/TabbedMaps/TabbedMaps';
import useMapTabs from '../../../hooks/useMapTabs';
import { FormattedMessage } from 'react-intl';

const MapPage = ({
  userLocation,
  ip,
  uuid,
  history,
  dealerLocations,
  tabId,
  title,
  zipcode,
  electricVehicles,
  usedElectricVehicles,
  homePage,
  evPage,
}) => {
  useEffect(() => {
    document.title =
      tabId === 'tabbed-maps-route-tab'
        ? 'Route Planner | Dominion Energy EV Shopper'
        : 'Charging Stations | Dominion Energy EV Shopper';
  });

  const { activeTabId, toggle, findTab } = useMapTabs(tabId);

  const toggleWithHistory = (newTabId) => {
    const newTab = findTab(newTabId);
    if (!homePage) {
      history.push(newTab.url);
    }
    toggle(newTab.id);
  };

  const home = homePage || evPage;

  return (
    <section
      className={
        homePage || evPage
          ? `container-home map home-map-section-container`
          : `container-fluid map-section-container`
      }
    >
      <div className={homePage ? 'home-map-section' : 'map-section'}>
        <div
          className={
            (home ? 'width100 ' : 'align-items ') + 'homepage-titles-container '
          }
        >
          <h1 className={home ? 'primary' : 'title-map'}>
            <FormattedMessage
              id={(home ? 'homepage' : 'page') + '.chargingMap.title'}
              defaultMessage="Recharge on the go, wherever you go."
              description="charge map title"
            />
          </h1>
          <p className={home ? 'HomepageSubTitle' : 'subtitle-map'}>
            <FormattedMessage
              id={(home ? 'homepage' : 'page') + '.chargingMap.subtitle'}
              defaultMessage="subtitle"
              description="charge map subtitle"
            />
          </p>
        </div>

        <TabbedMaps
          toggle={toggleWithHistory}
          activeTabId={activeTabId}
          userLocation={userLocation}
          dealerLocations={dealerLocations}
          zipcode={zipcode}
          electricVehicles={electricVehicles}
          usedElectricVehicles={usedElectricVehicles}
          isPage={home}
        />
      </div>
    </section>
  );
};

export default MapPage;

MapPage.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
