
const rateOffPeak = () => {
  return {
  name: 'Schedule 1G',
  title: 'Residential Service (Experimental)',
  rateElements: [
    // Fixed Monthly Charge
    {
      rateElementType: 'FixedPerMonth',
      name: 'Basic Customer Charge',
      rateComponents: [
        {
          charge: 7.58,
          name: 'Basic Customer Charge',
        },
      ],
    },
    // Energy Charges for Distribution kWh
    {
      rateElementType: 'EnergyTimeOfUse',
      name: 'Distribution kWh Charge - Summer',
      rateComponents: [
        {
          charge: 0.026247, // 2.6247¢ per kWh
          months: [5, 6, 7, 8], // May - August
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // All days
          hourStarts: [], // Need to define specific on-peak hours if available
          name: 'Summer On-Peak kWh Charge',
        },
        {
          charge: 0.024492, // 2.4492¢ per kWh
          months: [5, 6, 7, 8], // May - August
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // All days
          hourStarts: [], // Need to define specific off-peak hours if available
          name: 'Summer Off-Peak kWh Charge',
        },
        {
          charge: 0.017807, // 1.7807¢ per kWh
          months: [5, 6, 7, 8], // May - August
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // All days
          hourStarts: [], // Need to define specific super off-peak hours if available
          name: 'Summer Super Off-Peak kWh Charge',
        },
      ],
    },
    {
      rateElementType: 'EnergyTimeOfUse',
      name: 'Distribution kWh Charge - Winter',
      rateComponents: [
        {
          charge: 0.023152, // 2.3152¢ per kWh
          months: [9, 10, 11, 12, 1, 2, 3, 4], // September - April
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // All days
          hourStarts: [], // Need to define specific on-peak hours if available
          name: 'Winter On-Peak kWh Charge',
        },
        {
          charge: 0.021279, // 2.1279¢ per kWh
          months: [9, 10, 11, 12, 1, 2, 3, 4], // September - April
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // All days
          hourStarts: [], // Need to define specific off-peak hours if available
          name: 'Winter Off-Peak kWh Charge',
        },
        // Add similar components for Super Off-Peak if provided
      ],
    },
  ],
  }
};

export default rateOffPeak;
