import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import UserPrefsContext from '../../context/UserPrefs/UserPrefsContext';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { ValidateFiveDigitCode } from '../../utils/Helpers/USPostalCodeValidator';
import SelectVehicleIdForIncentives from '../InputComponents/SelectVehicleIdForIncentives.js/SelectVehicleIdForIncentives';
import ToolTip from '../shared/ToolTip/ToolTip';

import getClunkerIncentiveNames from '../../functions/incentives/getClunkerIncentiveNames';
import ChooseCanTurnInClunker from '../InputComponents/ChooseCanTurnInClunker/ChooseCanTurnInClunker';

import SlideHouseholdIncome from '../InputComponents/SlideHouseholdIncome/SlideHouseholdIncome';
import SelectHouseholdSize from '../InputComponents/SelectHouseholdSize/SelectHouseholdSize';
import ChooseTaxFilingStatus from '../InputComponents/ChooseTaxFilingStatus/ChooseTaxFilingStatus';
import ControlledInputZipcode from '../InputComponents/InputZipcode/ControlledInputZipcode';
import { FormattedMessage, useIntl } from 'react-intl';

import './IncentivePreferences.scss';

const IncentivePreferences = ({
  incentives,
  electricVehicles,
  titleText,
  btnSubtle,
  btnText,
  zipcode,
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  useEffect(() => {
    setWorkingZipcode(zipcode);
  }, [zipcode]);

  const [workingZipcode, setWorkingZipcode] = useState(zipcode);

  const handleFormSubmit = () => {
    if (ValidateFiveDigitCode(workingZipcode)) {
      userPrefs.syncWorkingZipcode();
      userPrefs.toggleIncentivePrefsModal();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !userPrefs.zipcodeIsUpdating) {
      handleFormSubmit();
    }
  };

  const RenderSelectVehicle = () => {
    return electricVehicles ? (
      <>
        <span className="h3">
          <FormattedMessage
            id="incentives.vehicleType"
            defaultMessage="Vehicle type"
            description="Vehicle type"
          />
          <ToolTip
            message={intl.formatMessage({
              id: 'incentives.vehicleTypeTooltip',
              defaultMessage:
                'Certain incentives depend on which car you purchase.',
            })}
            id="vehicle_type_tooltip"
          />
        </span>
        <SelectVehicleIdForIncentives electricVehicles={electricVehicles} />
      </>
    ) : (
      ''
    );
  };
  const RenderTradeIn = () => {
    return incentives ? (
      <>
        <span className="h5">
          <FormattedMessage
            id="incentives.tradeIn"
            defaultMessage="Trade-In"
            description="Trade-In"
          />
          <ToolTip
            message={getClunkerIncentiveNames(incentives, intl)}
            id="trade_in_label_tooltip"
          />
        </span>
        <ChooseCanTurnInClunker />
        <span className="h5">
          <FormattedMessage
            id="incentives.income"
            defaultMessage="Income and tax status"
            description="Income and tax status"
          />
          <ToolTip
            message={intl.formatMessage({
              id: 'incentives.incomeTooltip',
              defaultMessage:
                'Incentive eligibility and the amount you can receive often depends on your income. Lower  income customers are typically eligible for higher rebates.',
            })}
            id="income_and_tax_status_label_tooltip"
          />
        </span>
      </>
    ) : null;
  };

  const RenderAdditionalExtendedPreferences = () => {
    return process.env
      .REACT_APP_PAGES_INCENTIVES_SHOW_EXTENDED_PERSONALIZATION ? (
      <>
        <ChooseTaxFilingStatus />
        <SelectHouseholdSize />
        <SlideHouseholdIncome />
        <RenderTradeIn />
      </>
    ) : null;
  };

  const RenderButton = () => {
    let btnTextUpdating = userPrefs.zipcodeIsUpdating
      ? 'Updating...'
      : intl.formatMessage({
          id: 'incentives.incentiveEligibility',
          defaultMessage: 'Update Incentive Eligibility',
        });

    return (
      <button
        type="button"
        className="btn-dominion-yellow"
        onClick={() => handleFormSubmit()}
        disabled={userPrefs.zipcodeIsUpdating}
        id="update-zipcode-button"
      >
        {btnTextUpdating}
      </button>
    );
  };

  return (
    <>
      <button
        onClick={() => userPrefs.toggleIncentivePrefsModal(true)}
        className={btnSubtle ? 'btn btn-sm' : 'btn btn-dominion-yellow'}
        type="button"
      >
        {btnText}
      </button>
      <Modal
        isOpen={userPrefs.showIncentivePrefsModal}
        toggle={() => userPrefs.toggleIncentivePrefsModal()}
      >
        <ModalHeader toggle={userPrefs.toggleIncentivePrefsModal}>
          {intl.formatMessage
            ? intl.formatMessage({
                id: 'personalizeIncentives',
                defaultMessage: 'Personalize Incentives',
              })
            : 'Personalize Incentives'}
        </ModalHeader>
        <ModalBody>
          <div className="IncentivePreferences">
            <div className="input-well contained" onKeyDown={handleKeyDown}>
              <span className="h3">
                <FormattedMessage
                  id="incentives.location"
                  defaultMessage="Location"
                  description="Location"
                />
                <ToolTip
                  message={
                    intl.formatMessage
                      ? intl.formatMessage({
                          id: 'incentives.locationTooltip',
                          defaultMessage:
                            'Many incentives depend on where you live. Enter your zipcode to help determine what incentives are available where you live.',
                        })
                      : 'Many incentives depend on where you live. Enter your zipcode to help determine what incentives are available where you live.'
                  }
                  id="location_tooltip"
                />
              </span>
              <ControlledInputZipcode
                zipcode={workingZipcode}
                handleSubmit={(e) => {
                  handleFormSubmit();
                }}
                workingZipcode={workingZipcode}
                setZipcode={(e) => {
                  setWorkingZipcode(e.target.value);
                  if (ValidateFiveDigitCode(e.target.value)) {
                    userPrefs.set({ workingZipcode: e.target.value });
                  }
                }}
                id="input-zipcode-for-rate-comp-launch"
                isUpdating={userPrefs.zipcodeIsUpdating}
                isNotFound={userPrefs.zipcodeIsNotFound}
                hideValidateRequest
              />
              <RenderSelectVehicle />
              <RenderAdditionalExtendedPreferences />
              <div className="form-group">
                <RenderButton />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default IncentivePreferences;

IncentivePreferences.propTypes = {
  electricVehicles: PropTypes.array,
  titleText: PropTypes.string,
  btnText: PropTypes.string,
  btnSubtle: PropTypes.bool,
};
