import React, { useEffect, useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// Components
import InputPanel from '../../components/InputPanel/InputPanel';
import RateComparisonChart from '../../components/RateComparisonChart/RateComparisonChart';
import SelectCurrentRate from '../../components/InputComponents/SelectCurrentRate/SelectCurrentRate';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import SelectRateComparisonVehicle from '../../components/InputComponents/SelectRateComparisonVehicle/SelectRateComparisonVehicle';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import SlideMilesDrivenAnnually from '../../../components/InputComponents/SlideMilesDrivenAnnually/SlideMilesDrivenAnnually';
import SelectChargingPattern from '../../components/InputComponents/SelectChargingPattern/SelectChargingPattern';
import SlideElectricMilesPortionForPhev from '../../../components/InputComponents/SlideElectricMilesPortionForPhev/SlideElectricMilesPortionForPhev';
import SlideCurrentMonthlyBill from '../../components/InputComponents/SlideCurrentMonthlyBill/SlideCurrentMonthlyBill';
import SlideGasolinePrice from '../../../components/InputComponents/SlideGasolinePrice/SlideGasolinePrice';
import SlideEquivalentMilesPerGallon from '../../components/InputComponents/SlideEquivalentMilesPerGallon/SlideEquivalentMilesPerGallon';
import SlidePublicChargingPercentage from '../../components/InputComponents/SlidePublicChargingPercentage/SlidePublicChargingPercentage';
import SelectPublicChargingCost from '../../components/InputComponents/SelectPublicChargingCost/SelectPublicChargingCost';

// Data
import ratesData from '../../data/rates';
import chargingPatterns, {
  combinedLoadProfile,
} from '../../data/chargingPatterns';
import zipcodeToBaselineAllocationRegion from '../../data/zipcodeToBaselineAllocationRegion.json';

// Functions
import isPHEV from '../../../functions/vehicle/isPHEV';
import { FormatAsCents } from '../../../utils/Helpers/Format';
import getLoadProfileData from '../../data/loadProfiles/getLoadProfileData';
import totalElectricVehicleKwhPerYear from '../../functions/totalElectricVehicleKwhPerYear';
import RateCostCalculator from '../../functions/RateCostCalculator';
import GasolineCostCalculator from '../../functions/GasolineCostCalculator';
import calculateRateTotals from '../../functions/calculateRateTotals';
import rateComparisonChartData from '../../functions/rateComparisonChartData';

// Components
import RateComparisonBanner from '../../components/RateComparisonBanner/RateComparisonBanner';
import RateDetails from '../../components/RateDetails/RateDetails';
import RateOptionsWizard from '../../components/RateOptionsWizard/RateOptionsWizard';

// Styles
import './RateComparison.scss';

import { useIntl } from 'react-intl';
import ControlledInputZipcode from '../../../components/InputComponents/InputZipcode/ControlledInputZipcode';
import { ValidateFiveDigitCode } from '../../../utils/Helpers/USPostalCodeValidator';

import {
  FROM_12AM_TO_5AM,
  FROM_5AM_TO_3PM,
  FROM_3PM_TO_6PM,
  FROM_6PM_TO_12AM,
  FROM_5AM_TO_9AM,
  FROM_9AM_TO_5PM,
  FROM_5PM_TO_12AM,
  ALL_HOURS,
} from '../../constants/';

const RateComparison = ({ electricVehicles, ip, uuid, userLocation }) => {
  const intl = useIntl();
  useEffect(() => {
    document.title =
      process.env.REACT_APP_PAGES_RATES_TITLE || 'Dominion Energy Rate Advisor';
  });

  const userPrefs = useContext(UserPrefsContext);
  const {
    isComparingLowestRateOnly,
    vehicleIdForRateComparison,
    chargingPatternId,
    chargingPatternIdMaySep,
    chargingPatternIdOctApr,
    currentRateId,
    zipcode,
    hasSolarAtHome,
    milesDrivenAnnually,
    electricMilesPortionForPhev,
    publicChargingPercentage,
    publicChargingCostInCentsPerKwh,
    currentMonthlyBill,
    gasolinePriceInCentsPerGal,
    equivalentMilesPerGallon,
    selectedEnergyCostIds,
    currentRateClimateZone,
  } = userPrefs.get();

  const [selectedVehicle, setSelectedVehicle] = useState();
  const [bestSaving, setBestSaving] = useState();
  const [workingZipcode, setWorkingZipcode] = useState(
    userPrefs.get('zipcode')
  );
  const [rates, setRates] = useState(ratesData);
  const [inputFiltersOpen, setInputFiltersOpen] = useState(false);

  useEffect(() => {
    let newRates = { ...ratesData };

    if (userLocation?.region === 'North Carolina') {
      userPrefs.set({ selectedRateDetailsId: 'standard NC' });
      delete newRates['standard-vi'];
      delete newRates['offPeak'];

      userPrefs.set({ currentRateId: 'standard NC' });

    } else {
      userPrefs.set({ selectedRateDetailsId: 'standard VI' });
      delete newRates['standard-nc'];

      userPrefs.set({ currentRateId: 'standard VI' });

    }
    setRates(newRates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingZipcode, userLocation]);

  useEffect(() => {
    if (electricVehicles && vehicleIdForRateComparison) {
      setSelectedVehicle(
        electricVehicles.find(
          (ev) => ev.electric_vehicle_id === vehicleIdForRateComparison
        )
      );
    } else if (electricVehicles) {
      setSelectedVehicle(electricVehicles[0]);
    }
  }, [electricVehicles, vehicleIdForRateComparison]);

  const toggleInputFilters = () => {
    setInputFiltersOpen(!inputFiltersOpen);
  };

  const currentChargingPattern =
    chargingPatterns.find((p) => p.id === chargingPatternId) ||
    chargingPatterns[0];

  const currentChargingPatternMaySep =
    chargingPatterns.find((p) => p.id === chargingPatternIdMaySep) ||
    chargingPatterns[0];

  const currentChargingPatternOctApr =
    chargingPatterns.find((p) => p.id === chargingPatternIdOctApr) ||
    chargingPatterns[0];



  const currentTerritory = zipcodeToBaselineAllocationRegion[zipcode] || '8';

  const currentRate =
    Object.values(rates).find((e) => e.id === currentRateId) ||
    rates[currentRateId] ||
    rates[Object.keys(rates)[0]];

  const evaluatedCurrentRate = {
    ...currentRate,
    data: currentRate.data(currentTerritory, currentRateClimateZone),
  };

  const homeLoadProfile = getLoadProfileData({
    rateGroup: currentRateId,
    climateZone: currentRateClimateZone,
    hasSolar: hasSolarAtHome,
  });

  const combinedChargingPattern = combinedLoadProfile(
    currentChargingPatternMaySep.loadProfile,
    currentChargingPatternOctApr.loadProfile
  );

  const totalElectricVehicleKwh = totalElectricVehicleKwhPerYear({
    kwhPer100Miles:
      selectedVehicle && selectedVehicle.electric_efficiency
        ? selectedVehicle.electric_efficiency
        : 0,
    milesDrivenAnnually,
    percentageDrivenElectric: isPHEV(selectedVehicle)
      ? electricMilesPortionForPhev
      : 100,
  });

  const homeChargingKwh =
    totalElectricVehicleKwh * ((100 - publicChargingPercentage) / 100);

  const publicChargingElectricCostPerMonth =
    (totalElectricVehicleKwh *
      (publicChargingCostInCentsPerKwh / 100) *
      (publicChargingPercentage / 100)) /
    12;

  const rateCostCalculator = new RateCostCalculator({
    typicalHomeLoadProfile: homeLoadProfile,
    currentRate: evaluatedCurrentRate,
    currentMonthlyBill,
    homeChargingKwh,
    chargingPatternLoadProfile: combinedChargingPattern
      ? combinedChargingPattern
      : currentChargingPattern.loadProfile,
  });

  const gasolineCostCalculator = new GasolineCostCalculator({
    selectedVehicle,
    gasolinePriceInCentsPerGal,
    milesDrivenAnnually,
    electricMilesPortionForPhev,
    equivalentMilesPerGallon,
  });

  const evaluatedRates = Object.keys(rates).map((k) => ({
    ...rates[k],
    data: rates[k].data(currentTerritory, currentRateClimateZone),
  }));

  const rateTotals = calculateRateTotals({
    rates: evaluatedRates,
    currentRate: {
      ...currentRate,
      data: currentRate.data(currentTerritory, currentRateClimateZone),
    },
    rateCostCalculator,
    gasolineCostCalculator,
    publicChargingElectricCostPerMonth,
    selectedEnergyCostIds,
    isComparingLowestRateOnly,
  });

  const rsHome = rateTotals.find((obj) => obj.id.includes('standard')).home;
  const rlmEv =
    rateTotals.find((obj) => obj.id.includes('offPeak'))?.evCostValue ??
    rateTotals.find((obj) => obj.id.includes('standard NC'))?.evCostValue ?? 0;

  rateTotals[1].title = `${rateTotals[1].title} (with EV)`
  rateTotals.push({
    annual: 0,
    gas: 0,
    monthly: 0,
    isLowest: false,
    home: rsHome,
    ev: rlmEv,
    id: currentRate.id + '-with-ev',
    title: [`${currentRate.title[0]} (with EV)`],
  });

  const labelHome = intl.formatMessage({
    id: 'savingsGraphHomeLabel',
    defaultMessage: 'Home',
  });
  const labelElectric = intl.formatMessage({
    id: 'savingsGraphEVLabel',
    defaultMessage: 'Electric Vehicle',
  });
  const labelGasoline = intl.formatMessage({
    id: 'savingsGraphGasLabel',
    defaultMessage: 'Gasoline',
  });
  const chartData = rateComparisonChartData({
    rateTotals,
    labelHome,
    labelElectric,
    labelGasoline,
  });

  const handleFormSubmit = () => {
    if (ValidateFiveDigitCode(workingZipcode)) {
      userPrefs.syncWorkingZipcode();
      userPrefs.toggleIncentivePrefsModal();
    }
  };

  const maySepChargingPatterns = chargingPatterns.filter((e) =>
    [
      FROM_12AM_TO_5AM,
      FROM_5AM_TO_3PM,
      FROM_3PM_TO_6PM,
      FROM_6PM_TO_12AM,
      ALL_HOURS,
    ].includes(e.id)
  );

  const octAprChargingPatterns = chargingPatterns.filter((e) =>
    [
      FROM_12AM_TO_5AM,
      FROM_5AM_TO_9AM,
      FROM_9AM_TO_5PM,
      FROM_5PM_TO_12AM,
      ALL_HOURS,
    ].includes(e.id)
  );
  
  const ratesSideBar = () => {
    return (
      <>
        <InputPanel title="Basic Filters" className="mb-4">
          <ControlledInputZipcode
            zipcode={workingZipcode}
            handleSubmit={(e) => {
              handleFormSubmit();
            }}
            workingZipcode={workingZipcode}
            setZipcode={(e) => {
              setWorkingZipcode(e.target.value);
              if (ValidateFiveDigitCode(e.target.value)) {
                userPrefs.set({ workingZipcode: e.target.value });
              }
            }}
            id="input-zipcode-for-rate-comp-launch"
            isUpdating={userPrefs.zipcodeIsUpdating}
            isNotFound={userPrefs.zipcodeIsNotFound}
            hideValidateRequest
            userLocation={userLocation}
          />
          <SelectCurrentRate
            rates={Object.keys(rates).map((key) => rates[key])}
          />
          <SelectRateComparisonVehicle
            electricVehicles={electricVehicles}
            selectedVehicle={selectedVehicle}
          />
          <SlideMilesDrivenAnnually />
          <SelectChargingPattern
            chargingPatterns={maySepChargingPatterns}
            monthChargingPatternsId={'chargingPatternIdMaySep'}
            label="CHARGING PATTERN: MAY - SEP"
          />
          <SelectChargingPattern
            chargingPatterns={octAprChargingPatterns}
            monthChargingPatternsId={'chargingPatternIdOctApr'}
            label="CHARGING PATTERN: OCT - APR"
          />
        </InputPanel>
        <InputPanel
          title="Home Assumptions"
          className="mb-4"
          defaultIsCollapsed={false}
        >
          <SlideCurrentMonthlyBill id="monthly-slider-filters" />
          {/* <GenericInputZipcode buttonText="Update Zip Code" /> */}
          {/* <SelectHasSolarAtHome /> */}
        </InputPanel>
        <InputPanel
          title="Driving Assumptions"
          className="mb-4"
          defaultIsCollapsed={false}
        >
          {isPHEV(selectedVehicle) && (
            <SlideElectricMilesPortionForPhev
              label={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: 'electricPortion',
                      defaultMessage: 'Electric Portion',
                    })
                  : 'Electric Portion'
              }
              description={(val) =>
                `${val}% electric / ${100 - val} % gasoline`
              }
            />
          )}
          <SlideGasolinePrice
            label={
              intl.formatMessage
                ? intl.formatMessage({
                    id: 'gasolinePrice',
                    defaultMessage: 'Gasoline Price',
                  })
                : 'Gasoline Price'
            }
            description={(val) => `${FormatAsCents(val / 100)} / gallon`}
          />
          <SlideEquivalentMilesPerGallon />
          <SlidePublicChargingPercentage />
          <SelectPublicChargingCost />
        </InputPanel>
        <button
          className="btn btn-primary d-md-none apply-filters-button"
          type="button"
          onClick={() => toggleInputFilters()}
        >
          Apply Filters
        </button>
      </>
    );
  };

  return (
    <section className="container RateAdvisor">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h1 className="header">
            <FormattedMessage
              id="rateAdvisor.title"
              defaultMessage="Rate Adivsor"
              description="Rate Adivsor"
            />
          </h1>
          <h2 className="small-centered mb-bottom sub-header">
            <FormattedMessage
              id="rateAdvisor.sub"
              defaultMessage="Adjust the inputs below to find the right electric rate for you."
              description="Rate Advisor Subtitle"
            />
          </h2>
        </div>
      </div>

      {!electricVehicles ||
        (!selectedVehicle && (
          <div className="text-center">
            <LoadingSpinner />
          </div>
        ))}

      {electricVehicles && selectedVehicle && (
        <div className="row">
          <div className="col-md-3 rates-filters-container">
            <RateOptionsWizard
              electricVehicles={electricVehicles}
              rates={evaluatedRates}
              rateTotals={rateTotals}
              selectedVehicle={selectedVehicle}
              utilityName="SCE"
              typicalDriverName="Californian"
              bestSaving={bestSaving}
              userLocation={userLocation}
            />
            <button
              className="btn btn-primary d-md-none"
              type="button"
              onClick={() => toggleInputFilters()}
            >
              Open Filters
            </button>
            <Modal
              isOpen={inputFiltersOpen}
              toggle={() => toggleInputFilters()}
              className="rate-filters-modal"
            >
              <ModalHeader
                toggle={(e) => setInputFiltersOpen(!inputFiltersOpen)}
              >
                Rate Advisor Filters
              </ModalHeader>
              <ModalBody>{ratesSideBar()}</ModalBody>
            </Modal>
            {ratesSideBar()}
          </div>
          <div className="col-md-9 rates-graph-container">
            <div className="rate-comparison-title">
              <h3>
                <FormattedMessage
                  id="rateAdvisor.rateComparison"
                  defaultMessage="Rate Comparison"
                  description="Rate Comparison Title"
                />
              </h3>
            </div>
            <RateComparisonBanner
              rateTotals={rateTotals}
              selectedVehicle={selectedVehicle}
              bestSaving={bestSaving}
            />
            <RateComparisonChart
              title="Pricing Plan Comparison"
              chartData={chartData}
              className="d-md-block"
              setBestSaving={setBestSaving}
            />
            <div className="mt-4">
              <RateDetails
                rates={Object.keys(rates).map((k) => rates[k])}
                selectedRateDetailsId={userPrefs.get('selectedRateDetailsId')}
              />
            </div>
            <p className="rateAdvisorCaption">
              <FormattedMessage
                id="rateAdvisor.caption"
                defaultMessage="©2024 Dominion Energy EV Shopper. All rights reserved."
                description="©2024 Dominion Energy EV Shopper. All rights reserved."
              />
            </p>
          </div>
        </div>
      )}
    </section>
  );
};

export default RateComparison;

RateComparison.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
