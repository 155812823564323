
const basicCustomerChargeRate = {
  name: 'Basic Customer Charge',
  rateElementType: 'FixedPerMonth',
  rateComponents: [
    {
      charge: 10.67,
      name: 'Basic Customer Charge',
    },
  ],
};

const summerEnergyCharge = {
  name: 'Summer Energy Charge',
  rateElementType: 'EnergyTimeOfUse',
  rateComponents: [
    {
      charge: 0.108312, // 10.8312¢ per kWh
      months: [5, 6, 7, 8], // June through September
      name: 'Energy Charge (June to September)',
    },
  ],
};

const winterEnergyCharge = {
  name: 'Winter Energy Charge',
  rateElementType: 'EnergyTimeOfUse',
  rateComponents: [
    {
      charge: 0.093796, // 9.3796¢ per kWh
      months: [9, 10, 11, 0, 1, 2, 3, 4], // October through May
      name: 'Energy Charge (October to May)',
    },
  ],
};


const residentialRate = () => {
  return {
    name: 'Residential Service',
    title: 'Virginia Electric and Power Company Schedule 1',
    rateElements: [
      basicCustomerChargeRate,
      summerEnergyCharge,
      winterEnergyCharge,
    ],
  }
};

export default residentialRate;
