import React, { useContext, useState } from 'react'
import './ImpactChartSection.scss'
import ControlledInputZipcode from '../../../components/InputComponents/InputZipcode/ControlledInputZipcode';
import { ValidateFiveDigitCode } from '../../../utils/Helpers/USPostalCodeValidator';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import { Bar, Doughnut  } from "react-chartjs-2";
import 'chartjs-plugin-datalabels';
import { FormattedMessage } from 'react-intl';

const ImpactChartSection = ({userLocation}) => {
  const mobile = window.innerWidth < 911;
  const userPrefs = useContext(UserPrefsContext);
    const [workingZipcode, setWorkingZipcode] = useState(
        userPrefs.get('zipcode')
    );
    const isNorthCarolina = userLocation?.region?.toLowerCase() === 'north carolina' ?? false;
    const handleFormSubmit = () => {
        if (ValidateFiveDigitCode(workingZipcode)) {
          userPrefs.syncWorkingZipcode();
          userPrefs.toggleIncentivePrefsModal();
        }
    };

    const barEvLegend = isNorthCarolina ? 'EV in NC' : 'EV in VA'
    const BarChartData = {
        labels: ['Gas', 'Ev in USA', barEvLegend],
        datasets: [
            {
                label: '#',
                backgroundColor: ['#768692', '#8BB8E8', '#009639'],
                data: [439, 157, 51]
            }
        ]
    }

    const CHART_JS_OPTIONS = {
        maintainAspectRatio: false,
        tooltips: {
            enabled: false
        },
        scales: {
            xAxes: [{
                barThickness : 75,
                gridLines: {
                    display:false
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    max: 500,
                    display: false,
                },
                gridLines: {
                    display:false
                }   
            }]
        },
        legend: {
          display: false,
        },
        plugins: {
            datalabels: {
              display: (context) => {
                return context.datasetIndex === 0;
              },
              formatter: (value, context) => {
                if (context.datasetIndex === 0) {
                  if(value === 439) return isNorthCarolina ? '12,594 lbs' : '12,595 lbs'
                  if(value === 157) return '2,727 lbs'
                  if(value === 51) return isNorthCarolina ? '2,169 lbs' : '2,059 lbs'
                }
                return '';
              },
              align: 'end',
              anchor: 'end',
              color: '#000',
              font: {
                weight: 400,
                size: 16
              },
            },
            
          }
    };

    const DoughnutChartData = {
        labels: [
            `hydro: ${(userLocation?.regional_electricity[0]?.power_mix.hydro * 100).toFixed(0)}%`, 
            `natural gas: ${(userLocation?.regional_electricity[0]?.power_mix.natural_gas * 100).toFixed(0)}%`, 
            `nuclear: ${(userLocation?.regional_electricity[0]?.power_mix.nuclear * 100).toFixed(0)}%`, 
            `wind: ${(userLocation?.regional_electricity[0]?.power_mix.wind * 100).toFixed(0)}%`, 
            `coal: ${(userLocation?.regional_electricity[0]?.power_mix.coal * 100).toFixed(0)}%`, 
            `other: ${(userLocation?.regional_electricity[0]?.power_mix.other * 100).toFixed(0)}%`
        ],
        datasets: [
            {
                label: '#',
                backgroundColor: ['#1D4F91', '#EF8700', '#8BB8E8', '#AF95A6', '#768692', '#009639'],
                data: [
                    userLocation?.regional_electricity[0]?.power_mix.hydro ?? 0,
                    userLocation?.regional_electricity[0]?.power_mix.natural_gas ?? 0,
                    userLocation?.regional_electricity[0]?.power_mix.nuclear ?? 0,
                    userLocation?.regional_electricity[0]?.power_mix.wind ?? 0,
                    userLocation?.regional_electricity[0]?.power_mix.coal
                    ?? 0,
                    userLocation?.regional_electricity[0]?.power_mix.other ?? 0,
                ]
            }
        ]
    }

    const Doughnut_CHART_JS_OPTIONS = {
        maintainAspectRatio: false,
        tooltips: {
            enabled: false
        },
        legend: {
          display: true,
          position: mobile ? 'bottom' : 'right',
          labels: {
            boxWidth: 20,
            boxHeight: 20,
            fontColor: '#2B2B2B',
            fontSize: 18,
            fontWeight: 400,
            fontFamily: "Roboto, sans-serif"
          }
        },
        cutoutPercentage: 30,
        plugins: {
            datalabels: {
              display: (context) => {
                return '';
              },
              formatter: (value, context) => {
                return '';
              }
            },
          }
            
    };
    
    return (
        <section className="impact-wrapper">
            <div className="impact-container">
                <div className="top-container">
                    <h1>
                        <FormattedMessage
                            id="homepage.impact.title"
                            defaultMessage="Make an impact every time you recharge."
                        />
                    </h1>
                    <h2>
                        <FormattedMessage
                            id="homepage.impact.subtitle"
                            defaultMessage="{state}’s power mix means you can dramatically reduce your CO2 emissions by switching to an electric vehicle."
                            values={{state: userLocation?.region ?? ''}}
                        />
                    </h2>
                    
                    <div className="btn-container">
                        <ControlledInputZipcode
                            zipcode={workingZipcode}
                            handleSubmit={(e) => {
                                handleFormSubmit();
                            }}
                            workingZipcode={workingZipcode}
                            setZipcode={(e) => {
                                setWorkingZipcode(e.target.value);
                                if (ValidateFiveDigitCode(e.target.value)) {
                                userPrefs.set({ workingZipcode: e.target.value });
                                }
                            }}
                            id="input-zipcode-for-rate-comp-launch"
                            isUpdating={userPrefs.zipcodeIsUpdating}
                            isNotFound={userPrefs.zipcodeIsNotFound}
                            hideValidateRequest
                            userLocation={userLocation}
                        />
                    </div>
                </div>

                <div className="bottom-container">
                    <div className="bar-chart-wrapper">
                        <div className="bar-container">
                            <Bar 
                                data={BarChartData} 
                                options={CHART_JS_OPTIONS} 
                                type="verticalBar"      
                            />
                        </div>

                        <p>
                            <FormattedMessage
                                id="homepage.chart.bar-title"
                                defaultMessage="Average CO2 pollution / mile"
                            />
                        </p>

                        {!mobile && <span>
                            <FormattedMessage
                                id="homepage.chart.bar-title"
                                defaultMessage="*As of 2022. Data Courtesy of the AFDC"
                            />
                        </span>}
                    </div>

                    <div className="Doughnut-chart-wrapper">
                        <div className="Doughnut-container">
                            <Doughnut  
                                data={DoughnutChartData} 
                                options={Doughnut_CHART_JS_OPTIONS}
                                type="verticalBar"      
                            />
                        </div>

                        <div className='text-container'>
                            <p>
                                <FormattedMessage
                                    id="homepage.chart.bar-title"
                                    defaultMessage="{state} Power Mix"
                                    values={{state: userLocation?.region ?? ''}}
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ImpactChartSection