import React from "react";
import PropTypes from "prop-types";

import ShowHideButton from '../../ShowHideButton/ShowHideButton';

import {useIntl} from 'react-intl';

import "./ChargingStationsList.scss";

const ChargingStationsList = ({ chargingStations }) => {
  const intl = useIntl();
  
  return (
    <div className="ChargingStationsList">
      <ShowHideButton buttonText={intl.formatMessage ? intl.formatMessage({ id: "chargingMap.showFullListOfStations", defaultMessage: "SHOW FULL LIST OF STATIONS"}) : "SHOW FULL LIST OF STATIONS"}>
        <div className="charging-station-cards-container">
          <table className="station-table">
            <thead>
              <tr className="table-header">
                <th className="station-table-header">Station</th>
                <th className="station-table-header">Address</th>
                <th className="station-table-header">Type</th>
                <th className="station-table-header">Phone</th>
              </tr>
            </thead>
            <tbody>
              {chargingStations.map((station, index) => {
                const address = `${station.street_address}, ${station.city}, ${station.state} ${station.zip}`;
                
                return (
                <tr key={index} className="station-table-row">
                  <td className="station-table-cell">{station.station_name}</td>
                  <td className="station-table-cell">
                    {address} 
                  </td>
                  <td className="station-table-cell">{station.groups_with_access_code}</td>
                  <td className="station-table-cell">
                  {station.station_phone 
                    ? station.station_phone.split(' ').map((phone, idx) => (
                      <div key={idx}>
                        <a href={`tel:${phone}`} className="station-table-phone">
                          {phone}
                        </a>
                      </div>
                    ))
                    : <span>None</span>
                  }
                  </td>
                </tr>
              )})}
            </tbody>
          </table>
        </div>
      </ShowHideButton>
    </div>
  );
};

ChargingStationsList.propTypes = {
  chargingStations: PropTypes.array
};

ChargingStationsList.defaultProps = {
  chargingStations: []
};

export default ChargingStationsList;
