import React from "react";
import PropTypes from "prop-types";
import { UncontrolledTooltip } from "reactstrap";
import q from "../../../client_customizations/assets/images/icons/Tooltip.svg";

const ToolTip = ({ id, message, img }) => {
  return (
    <>
      <span id={id} style={{ display: 'inline-block' }}>
        <img
          src={img ? img : q}
          alt=""
          style={{ width: '16px', marginLeft: '5px', marginBottom: '3px' }}
        />
      </span>
      <UncontrolledTooltip
        placement="right"
        target={id}
        style={{ backgroundColor: '#172536' }}
      >
        <span>{message}</span>
      </UncontrolledTooltip>
    </>
  );
};

export default ToolTip;

ToolTip.propTypes = {
  id: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};
