import React from "react";
import {
  FormatCarModelAndTrim,
  FormatAsDollars,
} from "../../../utils/Helpers/Format";
import "./RateComparisonBanner.scss";
import { FormattedMessage } from "react-intl";

const RateComparisonBanner = ({ rateTotals, selectedVehicle, bestSaving }) => {
  const currentRateIdIsTOU5 = rateTotals[0].title[0] === "EV-TOU-5";
  const savings = bestSaving?.selectedRate?.savings;

  const isLowest = bestSaving?.isLowest;


  const highlightText = (
    <span className="highlight">
      {(savings && savings > 0) ? FormatAsDollars(savings.toFixed(0)) : 0 }
    </span>
  );

  const highlightText2 = (
    <span className="highlight">
      {bestSaving?.selectedRate?.label}
    </span>
  );

  let bannerText;

  if (currentRateIdIsTOU5) {
    bannerText = isLowest ? (
      <>
              <FormattedMessage
                     id="rateComparison.youAreAlreadySaving"
                     defaultMessage="You are already saving up to {highlightText} if you have an EV and use the {highlight} rate plan."
                     description="Footer Copyright"
                     values={{
                            highlightText: highlightText,
                            highlight: <span className="highlight">EV-TOU-5</span>
                     }}
              />
      </>
    ) : (
      <>
              <FormattedMessage
                     id="rateComparison.youAreSaving"
                     defaultMessage="You are saving up to {highlightText} if you have an EV and use the {highlight} rate plan."
                     description="Footer Copyright"
                     values={{
                            highlightText: highlightText,
                            highlight: <span className="highlight">EV-TOU-5</span>
                     }}
              />
      </>
    );
  } else {
    bannerText = isLowest ? (
      <>
              <FormattedMessage
                     id="rateComparison.youAreAlreadySavingVehicle"
                     defaultMessage="You are already saving up to {highlightText} per year by switching to {highlight} with a {selectedVehicle}."
                     description="Footer Copyright"
                     values={{
                            highlightText: highlightText,
                            highlight: bestSaving?.selectedRate?.label,
                            selectedVehicle: FormatCarModelAndTrim(selectedVehicle)
                     }}
              />
      </>
    ) : (
      <>
              <FormattedMessage
                     id="rateComparison.youAreSavingVehicle"
                     defaultMessage="You can save {highlightText} per year with an {selectedVehicle} on the {highlight}."
                     description="Footer Copyright"
                     values={{
                            highlightText: highlightText,
                            highlight: highlightText2,
                            selectedVehicle: FormatCarModelAndTrim(selectedVehicle)
                     }}
              />
      </>
    );
  }

  return (
    <div className="RateComparisonBanner">
      {bannerText}
    </div>
  );
};

export default RateComparisonBanner;
