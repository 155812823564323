import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./EVs.scss";

import EVCatalog from "./../../client_customizations/components/EVCatalog/EVCatalog";
import MatchScoreOptions from "./../../client_customizations/components/MatchScoreOptions/MatchScoreOptions";
import EvSortControls from "./../../components/EvSortControls/EvSortControls";

import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";

import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from "react-router-dom";

const EVs = ({ electricVehicles, isUsed }) => {
  const intl = useIntl()

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_VEHICLES_TITLE;
  });


  const mobile = window.innerWidth < 911;

  const conditionBtns = () => (
    <div className="condition-container">
        <Link to={`/vehicles`}>
          <button className={!isUsed  && "active"}>New</button>
        </Link>
        <Link to={`/used-vehicles`}>
          <button className={isUsed  && "active"} >Used</button>
        </Link>
      </div>
  )

  const renderOptions = (mobile) => (
    <>
      {conditionBtns()}
      {!mobile && <MatchScoreOptions  mobile={mobile} />}
    </>
  );


  return (
    <section className="container-fluid" id="EVs">
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12 text-center">
            <h1 className="header">
              <FormattedMessage
                id="evsCatalog.title"
                defaultMessage="DISCOVER NEW ELECTRIC VEHICLES"
                description="Electric Vehicles"
              />
            </h1>
            <h2 className="sub-header" >
              <FormattedMessage
                id="evsCatalog.subtitle"
                defaultMessage="Compare electric cars by EV range, price, or your personalized Match Score. {lineBreak} Click on the EV for more details, including total cost compared to a similar gas vehicle."
                description="Description of Colton Recharged"
                values={{
                  lineBreak: <br />,
                }}
              />
            </h2>
          </div>
        </div>

        <div className="vehicle-catalog-wrapper">
          {mobile && <div className="modal-btn-container">
            {conditionBtns()}
            <EvSortControls mobile={mobile} />
            <ModalComponent
              buttonText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: 'evs.buttonMatchScoreAndFilters',
                      defaultMessage: 'Match Score and Filters',
                    })
                  : 'Match Score and Filters'
              }
              titleText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: 'evs.matchScoreAndFilters',
                      defaultMessage: 'Refine Match Score and Filters',
                    })
                  : 'Refine Match Score and Filters'
              }
              submitText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: 'apply-filters',
                      defaultMessage: 'Apply Filters',
                    })
                  : 'Apply Filters'
              }
              disclaimerText={
                <FormattedMessage
                  id="evs.thirdParty"
                  defaultMessage="We do not endorse or recommend any specific vehicle or car manufacturer. Vehicles displayed may not reflect actual availability."
                  description="Vehicles Disclaimer"
                  values={{
                    company: process.env.REACT_APP_FULL_COMPANY_NAME,
                  }}
                />
              }
              isFullScreen
            >
              <MatchScoreOptions  mobile={mobile} />
            </ModalComponent>
          </div>}
          <div className="filter-container d-none d-lg-block">
            {renderOptions(mobile)}
          </div>

          <div className="catalog-container">
            <div className="evsort-row">
              {!mobile ? <EvSortControls mobile={mobile} /> : null}
            </div>
            <EVCatalog
              vehicles={electricVehicles}
              hasEvDetails
              hasIncentivesAndMatchScore
              isUsed
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EVs;

EVs.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
