import RLMSDetails from "./rates/RLMSDetails";
import RLMSDetailsNC from "./rates/RLMSDetailsNC";
import RSDetails from "./rates/RSDetails";
import residentialRate from "./rates/residentialRate"; // 1 Virginia
import rateOffPeak from "./rates/rateOffPeak"; // 1G virginia
import residentialRateNC from "./rates/residentialRateNorthCarolina";


//OBS: For the rates data, the max and min ALWAYS need to have 12 elements.

const rates = {
  "standard-vi": {
    id: "standard VI",
    title: ["VA Schedule 1: Standard Residential Service "],
    subTitle: [""],
    data: residentialRate,
    detailsComponent: RLMSDetails,
    isDisabled: false,
  },
  "standard-nc": {
    id: "standard NC",
    title: ["NC Schedule 1: Standard Residential Service "],
    subTitle: [""],
    data: residentialRateNC,
    detailsComponent: RLMSDetailsNC,
    isDisabled: false,
  },
  "offPeak": {
    id: "offPeak",
    title: ["Schedule 1G: Off-Peak Plan"],
    subTitle: [""],
    data: rateOffPeak,
    detailsComponent: RSDetails,
    isDisabled: false,
  }
};

export default rates;