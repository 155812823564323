import React, { useState } from "react";
import PropTypes from "prop-types";
import {  Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import './ModalComponent.scss'

const ModalComponent = ({ children, submitText, buttonText, titleText, disclaimerText, isFullScreen }) => {
  const [showModal, setModal] = useState(false);
  const toggle = () => {
    setModal(!showModal);
  };

  let renderFooter = submitText ? (
    <ModalFooter style={{border: "none"}}>
      <button className='btn-footer' style={{width: "100%"}} onClick={toggle}>
        {submitText}
      </button>
    </ModalFooter>
  ) : null;

  return (
    <div className="button-container">
      <button
        onClick={toggle}
        className="btn-open-filter"
        style={{ width: '100%', height: '54px' }}
        type="button"
      >
        {buttonText}
      </button>
      <Modal 
        isOpen={showModal} 
        toggle={toggle}
        className={`${isFullScreen && 'fullscreen'}`} 
      >
        <ModalHeader
          toggle={toggle}
        >
          {titleText}
        </ModalHeader>
        <ModalBody>
          {children}
          <div className="disclaimer-modal">{disclaimerText}</div>
          {renderFooter}  
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalComponent;

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  titleText: PropTypes.string,
  submitText: PropTypes.string
};
