import React, { useContext } from 'react';
import Select from '../../../../components/shared/InputElements/Select';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import { FormattedMessage } from 'react-intl';

const SelectChargingPattern = ({ chargingPatterns, monthChargingPatternsId, label }) => {
  const userPrefs = useContext(UserPrefsContext);
  const idKey = monthChargingPatternsId ? monthChargingPatternsId : 'chargingPatternId'; 
  const chargingPatternId = userPrefs.get(idKey);

  return (
    <Select
      id="home-charging-advisor-charging-pattern"
      value={chargingPatternId}
      optionNames={chargingPatterns.map(p =>  p.title)}
      optionValues={chargingPatterns.map(p => p.id)}
      handler={(e) => userPrefs.set({ [idKey]: e.target.value })}
      label={<FormattedMessage
        id="label-charging-pattern"
        defaultMessage={label || "Charging Pattern"}
        description="Charging Pattern"
      />}
    />
  );
};

export default SelectChargingPattern;
